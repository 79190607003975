body {
  .store__header {
    .fl-store__type {
      margin-bottom: 2rem;
  
      &__logo {
        display: block;
        width: 4.8rem;
        height: 1.8rem;
      }

      .fl-store__label {
        display: inline-block;
        padding: 0.4rem 0.6rem 0.2rem;
        font-size: 1.1rem;
        border-radius: 0.2rem;
        line-height: 1.4rem;
        text-transform: capitalize;
        background: $color-black;
        color: $color-white;

        &--monobrand {
          color: $color-black;
          background: #e9c6ba;
        }

        &--design-space {
          color: $color-black;
          background: #9fd5db;
        }
      }
    }
  }

  .fl-store__note {
    margin-top: 2.4rem;
  }
}