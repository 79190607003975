.product-detail__accordion {
  .accordion__item {
    border-color: $color-black;
    margin-left: -24px;
    margin-right: -24px;
    padding-left: 24px;
    padding-right: 24px;

    @include screen-tablet-landscape-and-bigger {
      margin-left: 0;
      margin-right: 0;
      padding-left: 0;
      padding-right: 0;
    }

    &:first-child {
      border-top: 1px solid $color-black;
    }
  }

  .accordion__item--specs .accordion__item-header {
    padding: 2.4rem 0 2rem 3.6rem;
    font-size: 2rem;
    line-height: 2.4rem;
    letter-spacing: 0.01em;
  }

  .accordion__item-icon {
    right: auto;
    left: 0;
  }

  .fl-specifications {
    display: flex;
    flex-direction: column;

    @include screen-tablet-landscape-and-bigger {
      flex-direction: row;
      align-items: flex-start;
      margin: 0 -2.4rem;
    }

    &__img {
      width: 100%;
      margin: 0 0 1.6rem;

      @include screen-tablet-landscape-and-bigger {
        width: 50%;
        flex: 0 0 50%;
        padding: 0 2.4rem;
        margin: 0;
      }

      img {
        display: block;
        width: 100%;
      }
    }

    &__table {
      width: 100%;

      @include screen-tablet-landscape-and-bigger {
        display: block;
        width: 50%;
        flex: 0 0 50%;
        padding: 0 2.4rem;
        margin-left: auto;
      }

      .definition-table {

        &__col {
          width: 100%;

          &:last-child .definition-table__line:last-child {
            border-bottom: none;
          }
        }

        &__dd {
          color: $color-black;
        }

        &__line {
          border-bottom: 1px solid var(--color-divider-line);
        }
      }
    }
  }

  .fl-download {
    display: flex;
    flex-direction: column;
    margin-bottom: 2.4rem;

    @include screen-tablet-landscape-and-bigger {
      width: 50%;
      margin-left: auto;
    }

    &__wrp {
      width: 100%;
      margin: 0;

      @include screen-tablet-landscape-and-bigger {
        padding: 0 0 0 2.4rem;
        margin: 0;
      }

      .c-button--underline .button__label {
        padding-bottom: 0;
        font-size: 1.6rem;
        line-height: 2.4rem;
      }

      .definition-table__col {
        width: 100%;
        position: relative;
      }

      .definition-table__line {
        border-bottom: 1px solid var(--color-divider-line) !important;
        margin-bottom: 1.2rem;
        padding-bottom: 1.2rem !important;

        @include screen-tablet-landscape-and-bigger {
          margin-bottom: 1.6rem;
          padding-bottom: 1.6rem !important;
        }
      }
    }

    a {
      position: static;

      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
      }
    }

    &__link {
      position: relative;
      color: $color-black;

      &__description {
        display: block;
        margin: 0.8rem 0 0 0;
        font-size: 1.4rem;
        letter-spacing: 0.01em;
        color: $color-black;
        opacity: 0.5;
      }

      &--sheet {
        display: none;
      }

      &__icon {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        width: 2.4rem;
        height: 2.4rem;
        fill: $color-black;
      }
    }
  }

  &.fl-product__usps {
    display: block;
    padding: 0.8rem 2rem;
    background: $color-white;

    @include screen-tablet-portrait-and-bigger {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding: 1.6rem;
    }

    .fl-product__usps__item {
      color: $color-black;

      @include screen-tablet-portrait-and-bigger {
        width: 50%;
        flex: 0 0 50%;
        padding: 0 1.6rem;
      }

      &:last-child {
        .accordion__item {
          border-bottom: none;
        }
      }

      &:nth-last-child(2):nth-child(odd) {
        .accordion__item {
          @include screen-tablet-portrait-and-bigger {
            border-bottom: none;
          }
        }
      }

      .accordion__item {
        border-bottom: 1px solid $color-light-grey;
        padding: 0;
        margin: 0;

        &:first-child {
          border-top: none;
        }
      }

      .icon--plus {
        width: 1.4rem;
        height: 1.4rem;
      }

      .accordion__item--specs .accordion__item-header {
        display: flex;
        align-items: center;
        font-weight: $font-weight-semi-medium;
        font-size: 1.6rem;
        line-height: 2rem;
        text-transform: none;
        padding: 1.6rem 1.6rem 1.6rem 0;

        span {
          flex: 1;
          padding-left: 1.6rem;

          &.accordion__item-icon {
            padding: 0;
          }
        }
      }

      .accordion__item-icon {
        right: 0;
        left: auto;
      }
    }

  }
}