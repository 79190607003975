$header-language-selector-list-spacing: 33px;
.c-header {
  @include z(header);
  width: 100%;
  top: 0;
  transition: transform 500ms linear;
  background-color: var(--color-background-html);
  @if variable-exists('show-body-background-image') {
    @include background-grain;
  }

  @supports (position: sticky) {
    position: sticky;
  }

  .header--is-hidden & {
    transform: translate3d(0, -250%, 0);
  }

  .header--is-hidden &.html:not(.header-hero) {
    transition: transform 300ms linear;
    transform: translate3d(0, -100%, 0);
  }

  &.header--error {
    padding-top: $global-spacing-lg;
  }

  .header--mega-menu-open & {
    background-color: var(--color-background-html);
    background-image: var(--color-background-image);
  }

  .c-button {
    color: currentColor !important;
  }

  .button__icon {
    .header--is-scrolled-up & {
      color: $color-black !important;
    }
  }
}

.header__top {
  @include z(headerTop);
  height: $header-height-top;
  position: relative;
  &::before {
    top: -1px;
  }
}

.header__top-left {
  width: 100%;
  @include screen-tablet-landscape-and-bigger {
    margin-right: auto;
  }
}

.header__usps {
  height: $header-height-top;
}

.header__top-right {
  flex-shrink: 0;
  display: none;
  @include screen-tablet-portrait-and-bigger {
    display: flex;
    column-gap: $global-spacing-lg;
  }
}

.header__top-right-language-selector {
  .language-selector__list {
    top: $header-language-selector-list-spacing;
    .header--is-hidden & {
      display: none;
    }
  }
}

.header__top-label {
  @include text-small;
  font-style: italic;
}

.header__top-link {
  @include underline-button-clean;
  margin-left: $global-spacing-xlg;
}

.header__bottom-wrapper {
  position: relative;
}

.header__bottom {
  @include divider-line(var(--color-text), bottom, after, false);
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: $header-height-bottom;

  html:not(.header--is-hidden):not(.header--is-scrolled-up):not(.search--is-in-focus) .header--remove-bottom-line & {
    &::after {
      background-color: transparent;
    }
  }
}

.header__bottom-left {
  display: flex;
  align-items: center;
}

.header__logo {
  opacity: 1;
  transition: 400ms ease-in-out;
  transition-property: width, opacity;
  margin-right: $global-spacing-md;

  .header--hide-small-logo & {
    opacity: 0;
    width: 0;
  }

  svg {
    width: 114px !important;
    height: 43px !important;
  }
}

.header__bottom-right {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .search__button-close {
    color: $color-black;
  }
}

.header__secure {
  display: inline-flex;
  align-items: center;
  @include text-small;

  svg {
    margin-right: 20px;
  }
}

.header__buttons {
  display: flex;
  align-items: center;
}

.header__menu-toggle {
  margin-right: -10px;
  @include z(mobileNavigationToggleButton);
}

.header--hide-small-logo {
  @include screen-tablet-portrait-and-smaller {
    .header__bottom-right {
      width: 100%;
    }

    .navigation__search {
      margin-left: -20px;
      margin-right: auto;
    }

    .search__toggle-button {
      .button__label {
        display: block;
      }
    }
  }
}

.navigation__search {
  .button__icon {
    transition: initial !important;
  }
}

.header__switch-button {
  @include text-small;
  font-weight: $font-weight-medium;
  text-decoration: none;
  margin: 0;
  padding: 0;
  border: none;
  letter-spacing: 0.5px;
}

.header__switch-button--professionals {
  .button__label {
    background-image: linear-gradient(transparent, transparent);
  }
}

.search--is-in-focus {
  .header__switch-button {
    border-color: $color-black;
  }
}

.button--profile,
.site-header__minicart,
.navigation__search {
  .button__icon:hover {
    color: initial !important;
  }
}

.header--home-page {
  @supports (position: fixed) {
    position: fixed;
  }

  .header__bottom::after {
    content: none;
  }

  html:not(.header--is-hidden) & {
    background-color: $color-white;
    color: initial;
    border-color: $color-white;
  }

  html:not(.header--is-hidden):not(.header--is-scrolled-up):not(.search--is-in-focus) & {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 100%);
    color: $color-white;

    .header__switch-button--shop {
      .button__label {
        background-image: linear-gradient(var(--color-background-html), var(--color-background-html));
      }
    }

    .header__switch-button--shop,
    .header__switch-button--professionals {
      &:hover {
        .button__label {
          background-image: linear-gradient(var(--color-background-html), var(--color-background-html)) !important;
        }
      }
    }
  }

  .header--mega-menu-open & {
    background-color: var(--color-background-html) !important;
    background-image: var(--color-background-image) !important;
    color: initial !important;

    .button--profile {
      color: $color-black;
    }
  }

  .header--is-hidden & {
    transition: transform 300ms linear, background-color 400ms ease-in, background-image 400ms ease-in, color 400ms ease-in;
    background-color: transparent;
    background-image: initial;
    color: initial;
  }

  .header__switch-button {
    .header--is-scrolled-up &,
    .header--mega-menu-open & {
      border-color: $color-black;
    }
  }

  .navigation__search {
    .button__icon:hover {
      color: $color-white !important;

      .search--is-in-focus &,
      .header--is-scrolled-up & {
        color: $color-black !important;
      }
    }
  }

  .button--profile,
  .site-header__minicart {
    .button__icon:hover {
      color: $color-white !important;

      .header--is-scrolled-up & {
        color: $color-black !important;
      }
    }
  }
}


html.header-hero {
  .c-header{
    margin-top: $global-spacing-xxlg + 4;

    @include screen-tablet-landscape-and-bigger {
      margin-top: $global-spacing-xxxxlg - 10;
    }
  }

  .c-hero {
    margin-top: $global-spacing + 4;

    @include screen-tablet-landscape-and-bigger {
      margin-top: $global-spacing-lg - 3;
    }
  }

  .c-mobile-navigation {
    top: var(--header-hero-height);
  }

  .hero__text-content > :first-child {
    margin-top: $global-spacing-xxxxlg;

    @include screen-tablet-landscape-and-bigger {
      margin-top: 0;
    }
  }

  .search__modal {
    height: calc(var(--vh, 1vh) * 100 - var(--header-height) + 5px);
    margin-top: -($global-spacing - 3px);
  }

  .c-arco {
    overflow: auto;

    @include screen-tablet-landscape-and-bigger {
      padding-bottom: $global-spacing-xxxlg;
    }
  }
}
