.o-section {
  clear: both;
  position: relative;
}

// To add background color to a full width section + updated spacings
// Styling is only applied when $show-full-background-section-color / $show-column-background-section-color variable is set in brand vars
.section--background {
  @if variable-exists('show-full-background-section-color'){
    margin: 0;
    padding-top: var(--default-block-space-mobile);
    padding-bottom: var(--default-block-space-mobile);
    background-color: var(--color-background-column);
  }

  &.u-spacing--default-block,
  &.u-spacing--default-block-small {
    @if variable-exists('show-full-background-section-color') {
      margin-bottom: 0;
    }
  }

  &.u-spacing--default-block {
    @if variable-exists('show-full-background-section-color'){
      @include screen-tablet-landscape-and-bigger {
        padding-top: var(--default-block-space-desktop);
        padding-bottom: var(--default-block-space-desktop);
      }
    }
  }

  + .section--background {
    @if variable-exists('show-full-background-section-color') {
      padding-top: 0;
    }
  }

  @if variable-exists('show-column-background-section-color'){
    .o-container {
      position: relative;
      padding-top: $global-spacing-lg;

      @include screen-tablet-landscape-and-bigger {
        padding-top: $global-spacing-xxlg;
        padding-bottom: $global-spacing-md;
      }

      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: $global-spacing-md;
        width: calc(100% - #{$global-spacing-md * 2});
        height: 100%;
        z-index: -1;
        background-color: var(--color-background-column);

        @include screen-tablet-landscape-and-bigger {
          left: $global-spacing-xlg;
          width: calc(100% - #{$global-spacing-xlg * 2});
        }
      }
    }
  }
}
