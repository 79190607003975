.c-blogs-list {
  .blogs-list__pagination {
    @include screen-tablet-portrait-and-smaller {
      position: relative;
    }
  }

  .blogs-list__arrow-left,
  .blogs-list__arrow-right {
    @include screen-tablet-portrait-and-smaller {
      position: absolute;
    }
  }

  .blogs-list__arrow-left {
    @include screen-tablet-portrait-and-smaller {
      left: 0;
    }
  }

  .blogs-list__arrow-right {
    @include screen-tablet-portrait-and-smaller {
      right: 0;
    }
  }
}
