body {

  &.fl-zoom-open .fl-pdp-slider.tns-slider {
    transform: none !important;
  }

  .product-detail__image-grid {
    position: relative;
    margin-top: 0;

    .tns-nav {
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 1.6rem 1.2rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      z-index: 2;

      button {
        display: block;
        width: 100%;
        height: 0.2rem;
        flex: 1;
        margin: 0 0.4rem;
        border-radius: 0.4rem;
        background: $color-white;
        opacity: 0.4;
        border: none;
        transition: all 0.3s ease-in-out;

        &.tns-nav-active {
          opacity: 1;
        }
      }
    }

    .image-zoom__image-wrapper {
      position: relative;

      @include screen-tablet-landscape-and-bigger {
        position: initial;
      }

      &::before {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 7.4rem;
        background: linear-gradient(0deg, rgba(0,0,0,0.15) 0%, rgba(0,0,0,0) 100%);
        z-index: 3;
        pointer-events: none;

        @include screen-tablet-landscape-and-bigger {
          display: none;
        }
      }
    }

    .fl-button-zoom-icon {
      position: absolute;
      bottom: 4rem;
      right: 1.6rem;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 4rem;
      height: 4rem;
      background-color: $color-white;
      color: var(--color-text);
      border: none;
      transition: background-color 300ms $ease-out-quad;
      z-index: 5;
      pointer-events: none;
      line-height: 0;

      @include screen-tablet-landscape-and-bigger {
        display: none;
      }

      @include hover-focus {
        background-color: var(--color-background-inverted-10);
      }

      svg {
        max-width: 2.4rem;
        max-height: 2.4rem;
        fill: transparent;
      }
    }
  }

  .image-zoom__gallery-wrapper {

    &:not(.tns-slider) {
      display: flex;
      flex-wrap: nowrap;
      overflow-x: auto;

      @include screen-tablet-landscape-and-bigger {
        flex-wrap: wrap;
        overflow: initial;
      }
    }

    @include screen-tablet-landscape-and-bigger {
      display: flex;
      flex-wrap: wrap;
      overflow: initial;
    }

    > .product-detail__grid-image-wrapper {
      width: 100%;
      flex: 0 0 100%;

      .c-image {
        aspect-ratio: initial;
        width: 100%;
        height: auto;
      }
    }

    &.fl-imgnumber-more {
      > .product-detail__grid-image-wrapper:first-child,
      > .product-detail__grid-image-wrapper:nth-child(2) {
        @include screen-tablet-landscape-and-bigger {
          width: 50%;
          flex: 0 0 50%;

          .c-image img {
            aspect-ratio: 1;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
  }

  .c-image-zoom {
    background: $color-white;

    &:not(.image-zoom--fullscreen) {
      img {
        aspect-ratio: 1;
        width: 100%;
        height: 100%;
        object-fit: cover;

        @include screen-tablet-landscape-and-bigger {
          aspect-ratio: initial;
          height: auto;
          object-fit: initial;
        }
      }
    }

    &.image-zoom--fullscreen {
      .image-zoom__click-area {
        display: block;
      }
    }

    .image-zoom {
      &__click-area {
        display: none;
      }
    }

    .image-zoom__full-screen-button {
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      border: none;
      background: transparent;

      @include hover-focus {
        background-color: transparent;
      }
    }

    .image-zoom__button {
      border: none;
    }

    .image-zoom__prev-button,
    .image-zoom__next-button {
      bottom: auto;
      top: calc(50% - 2rem);
      background: $color-white-transparent-75;

      @include hover-focus {
        background: $color-white;
      }
    }

    .image-zoom__prev-button {
      right: auto;
      left: 2rem;
    }
    
    .image-zoom__next-button {
      right: 2rem;
    }
  }

  .fl-btn-gallery {
    display: none;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    @include screen-tablet-landscape-and-bigger {
      display: flex;
    }

    button {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin: 2.4rem auto;
      width: auto;
      padding: 0;
      border: none;
      background: transparent;

      svg {
        max-width: 2.4rem;
        max-height: 2.4rem;
        fill: transparent;
      }
    }

    &__text {
      display: block;
      font-weight: $font-weight-extrabold;
      font-size: 1.6rem;
      line-height: 2.4rem;
      margin: 0 0.8rem 0 0;
    }
  }

  .image-zoom--fullscreen {
    .image-zoom__highres-image-wrapper {
      background: $color-white;

      .image-zoom__highres-image {
        height: 100%;
      }
  
      .c-image [js-hook-objectfit-img] {
        object-fit: contain !important;
      }
    }
  }

  .fl-video-wrp {
    background: $color-black;

    .product-detail__video {
      margin: 0 !important;
      // padding-bottom: 133.33333%;
    }

    &--mobile {
      display: block;

      @include screen-tablet-landscape-and-bigger {
        display: none;
      }
    }

    &--desktop {
      display: none;

      @include screen-tablet-landscape-and-bigger {
        display: block;
      }
    }
  }
}