.c-store {
  position: relative;
  padding: $global-spacing * 3 0;

  a {
    text-decoration: none;
  }

  &:first-child {
    padding-top: 0;
  }
}

.store__accordion-item {
  border-style: solid;
  border-width: 1px 0;
  border-color: $color-tinted-grey;

  &:first-child:not(.accordion__item--is-active) {
    border-top: 0;
  }

  .accordion__item-icon {
    top: $global-spacing * 2;
    right: 0;
    width: 12px;
  }

  .c-icon {
    width: 100%;
    height: auto;
  }

  .accordion__item-content,
  .accordion__item-header {
    font-weight: inherit;
    padding: 0;

    &::after,
    &::before {
      content: none;
    }
  }

  .accordion__item-header {
    user-select: initial !important;
  }

  & + &:not(.accordion__item--is-active) {
    border-top-color: $color-tinted-grey;
  }
}

.store__accordion-item--hidden {
  display: none;
}

.store__header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  cursor: pointer;
}

.store__info-name {
  text-transform: initial;
  font-size: $font-size-body;
  font-weight: $font-weight-regular;
}

.store__distance {
  @include text-small;

  padding-top: $global-spacing-md;
  margin-bottom: 0;
  font-weight: $font-weight-regular;
}

.store__infos {
  @include text-small;

  font-style: normal;
  color: $color-black;
  padding-top: $global-spacing / 2;
}

.store__hours {
  display: flex;
  flex-wrap: wrap;
  margin-top: $global-spacing;
}

.store__hours-wrap {
  @include text-small;

  flex: 1 1 100%;
  display: flex;
  color: $color-tinted-grey;
}

.store__hours-time {
  padding-left: $global-spacing;
}

.store__infos-line {
  display: block;
  color: $color-tinted-grey;
}

.store__links {
  @include text-small;

  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: $global-spacing * 2;

  @include screen-tablet-landscape-and-bigger {
    flex-direction: column;
    align-items: initial;
  }

  @include screen-laptop-and-bigger {
    flex-direction: row;
    align-items: center;
  }
}

.store__links-icon {
  @include screen-tablet-landscape-and-bigger {
    margin-top: $global-spacing * 2;
  }

  @include screen-laptop-and-bigger {
    margin-top: 0;
  }
}

.store__links-button {
  &[href=''] {
    display: none;
  }

  + .store__links-button {
    @include screen-tablet-landscape-and-bigger {
      margin-left: $global-spacing * 3;
    }
  }
}

.store__maps-link {
  display: flex;
  align-items: center;

  img {
    margin-right: $global-spacing;
  }
}

.store__show-more,
.store__show-less {
  display: none;
  width: 100%;
  border: 1px solid $color-white;
  padding: $global-spacing-sm 0;
  cursor: pointer;
}

.store__show-more--visible,
.store__show-less--visible {
  display: block;
}

.store__show-more--map-is-active,
.store__show-less--map-is-active {
  display: none !important;
}

// Store inventory variant
.store--store-inventory {
  border-top: 0;
  margin-bottom: $global-spacing-xs;
  padding: $global-spacing-md;

  @include screen-tablet-landscape-and-bigger {
    padding: $global-spacing-md;
  }

  .store__info {
    width: 100%;
  }

  .store__name-container {
    display: flex;
    justify-content: space-between;
  }

  .store__phone-button {
    flex-shrink: 0;
  }

  .accordion__item-icon {
    display: none;
  }
}
