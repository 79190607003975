.c-flyout-menu {
  max-height: calc(100vh - var(--header-height));
  overflow-y: auto;
  overflow-x: hidden;
  color: var(--color-text);
  position: relative;
  opacity: 1;
  transition: 200ms opacity;

  .header--hide-flyout & {
    opacity: 0;
    transition-duration: 0ms;
  }

  .o-container {
    height: 100%;
  }

  .o-grid {
    min-height: 100%;
  }
}

.flyout-menu__items {
  display: flex;
  flex-wrap: wrap;
}

.flyout-menu__item {
  width: 33.333%;
  padding-right: $global-spacing-lg;
  margin-bottom: $global-spacing-xxlg;
}

.flyout-menu__links {
  margin-top: auto;
  border-top: 1px solid var(--color-divider-line);
  padding: $global-spacing-md 0 $global-spacing-lg 0;
}
