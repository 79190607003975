.c-store {
  position: relative;
  padding: $global-spacing * 2.5 0;

  .store__maps-link {
    font-size: rem(14);
    font-weight: 700;
    text-decoration: none;
  }

  @include screen-tablet-landscape-and-bigger {
    padding: $global-spacing * 4.5 0
  }
}

.c-map {

  button {
    background-color: $color-black !important;
  }

  .gm-svpc {
    background-color: $color-black !important;
  }

  .gm-style-cc {
    button {
      background: none !important;
    }
  }
}

.store__accordion-item {
  border-bottom: rem(1) solid $color-grey;
  border-top: none;

  .accordion__item-icon {
    top: $global-spacing * 2;
    right: 0;
    width: rem(12);
  }

  .c-icon {
    width: 19px;
    height: 17px;
    margin-right: 4px;
  }

  .c-icon.icon--arrow-right-arclinea {
    margin-left: rem(5);
    width: rem(20);
    height: rem(16);
  }

  .c-icon.icon--arrow-diagonal {
    margin-top: rem(5);
    min-height: rem(14);
    min-width: rem(14);
    height: rem(14);
    width: rem(14);
  }

  .accordion__item-content,
  .accordion__item-header {
    font-weight: inherit;
    padding: 0;

    &::after,
    &::before {
      content: none;
    }
  }

  .accordion__item-header {
    user-select: initial !important;
  }
}

.store__accordion-item--hidden {
  display: none;
}

.store__header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  cursor: pointer;
}

.store__info-name {
  font-size: rem(24);
  line-height: rem(28);
  font-weight: 700;
  margin-bottom: 0;
  text-transform: capitalize;
  padding-bottom: 0;
}

.store__info-store-type {
  display: inline-block;
  padding: $global-spacing - 3 $global-spacing + 2;
  font-size: rem(12);
  line-height: rem(16);
  font-weight: 700;
  text-transform: capitalize;
  background: $color-black;
  color: $color-white;

  @include screen-tablet-landscape-and-bigger {
    margin-bottom: 0;
  }
}

.store__distance {
  @include text-small;

  padding-top: $global-spacing-md;
  margin-bottom: 0;
  font-weight: $font-weight-regular;
}

.store__infos {
  @include text;

  user-select: text;
  cursor: default;
  font-style: normal;
  color: $color-black;
  padding-top: 0.2rem;
}

.store__hours {
  display: flex;
  flex-wrap: wrap;
  margin-top: $global-spacing;
}

.store__hours-wrap {
  @include text-small;

  flex: 1 1 100%;
  display: flex;
  color: $color-tinted-grey;
}

.store__hours-time {
  padding-left: $global-spacing;
}

.store__infos-line {
  display: block;
  color: $color-black;

  &--email {
    margin-top: $global-spacing * 2.750;
    text-decoration: underline;

    @include screen-tablet-landscape-and-bigger {
      margin-top: 0;
    }
  }
}

.store__links {
  @include text-small;

  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: $global-spacing * 3.5;

  @include screen-tablet-landscape-and-bigger {
    flex-direction: column;
    align-items: initial;
  }

  @include screen-laptop-and-bigger {
    flex-direction: row;
    align-items: center;
  }
}

.store__links-icon {
  @include screen-tablet-landscape-and-bigger {
    margin-top: $global-spacing * 2;
  }

  @include screen-laptop-and-bigger {
    margin-top: 0;
  }

  svg {
    fill: $color-white;
    width: 19px;
    height: 17px;
  }
}

.store__links-button {
  &[href=''] {
    display: none;
  }

  + .store__links-button {
    @include screen-tablet-landscape-and-bigger {
      margin-left: $global-spacing * 3;
    }
  }
}

.store__maps-link {
  display: flex;
  align-items: center;

  img {
    margin-right: $global-spacing;
  }

  @include hover-focus {
    color: gray;
  }
}

.c-button.c-button--primary {
  &.store__show-more,
  &.store__show-less {
    display: none;
    width: fit-content;
    font-size: $global-spacing * 2;
    font-weight: 700;
    margin-top: $global-spacing * 4;
    padding: $global-spacing-sm 0;
    cursor: pointer;
    text-align: center;
    color: $color-white;
    text-transform: capitalize;
    background-color: $color-black;

    @include hover-focus {
      color: $color-white !important;
    }

    .button__label {
      text-transform: none;
    }
  }

  &.store__show-more--visible,
  &.store__show-less--visible {
    display: inline-block;
  }

  &.store__show-more--map-is-active,
  &.store__show-less--map-is-active {
    display: none !important;
  }
}

// Store inventory variant
.store--store-inventory {
  border-top: 0;
  margin-bottom: $global-spacing-xs;
  padding: $global-spacing-md;

  @include screen-tablet-landscape-and-bigger {
    padding: $global-spacing-md;
  }

  .store__info {
    width: 100%;
  }

  .store__name-container {
    display: flex;
    justify-content: space-between;
  }

  .store__phone-button {
    flex-shrink: 0;
  }

  .accordion__item-icon {
    display: none;
  }
}
