@mixin button--arrow {
  padding: 0;

  .button__icon,
  .button__label {
    margin-right: rem(10);
  }

  .button__icon {
    svg {
      width: rem(19);
      height: rem(8);
    }
  }
}
