.c-basic-entry {
  @include default-pd-asset-space;
  display: block;
  position: relative;
  text-decoration: none;
  background-image: none;

  @include hover-focus {
    .basic-entry__image .image__default {
      transform: scale(1.05);
    }
  }

  @include screen-tablet-portrait-and-smaller {
    .u-spacing--default-block-small *[class*='o-col']:last-child & {
      margin-bottom: -$global-spacing;
    }
  }

  .basic-entry__image {
    @include screen-tablet-portrait-and-bigger {
      @include aspect-ratio(4/3);
    }
  }
}

.basic-entry--aspect-ratio-3-4 {
  .basic-entry__image {
    @include screen-tablet-portrait-and-bigger {
      @include aspect-ratio(3/4);
    }
  }
}

.basic-entry--brand-image {
  @include hover-focus {
    .basic-entry--second-image {
      opacity: 1;
      transition: opacity 300ms ease-in-out;
    }
  }
}

.basic-entry__title {
  @include h4;
  margin-top: $global-spacing-sm;
}

.basic-entry__subtitle {
  display: block;
  margin-top: $global-spacing-xs;
}

.basic-entry__body {
  display: block;
  margin-top: $global-spacing-xs;
}
