.c-large-image {
  @include default-block-space;
  padding-top: rem(15);

  @include screen-tablet-portrait-and-bigger {
    padding-top: rem(20);
  }

  .large-image__title {
    margin-bottom: rem(10);

    @include screen-laptop-and-bigger {
      line-height: 0.75;
    }
  }

  .large-image__designer {
    @include screen-laptop-and-bigger {
      display: inline-block;
      color: var(--color-black-transparent-60);
    }
  }

  .large-image__media-wrapper {
    margin: 0 rem(-20);
    width: calc(100% + 35px);
    padding: 0;
    max-width: none;
    flex-basis: auto;

    @include screen-tablet-portrait-and-bigger {
      margin: 0 rem(-40);
      width: calc(100% + 70px);
    }

    @include screen-laptop-and-bigger {
      margin: 0;
      width: 100%;
      padding: 0 rem(10);
    }
  }

  .large-image__img {
    @include aspect-ratio(5/4);
    padding-bottom: rem(40);

    @include screen-laptop-and-bigger {
      @include aspect-ratio(16/9);
    }
  }

  .large-image__subtitle {
    margin-bottom: rem(30);

    @include screen-laptop-and-bigger {
      margin-bottom: 0;
    }
  }

  .large-image__link {
    @include screen-laptop-and-bigger {
      color: var(--color-black-transparent-60);
    }
  }
}

.c-large-image--no-link {
  .large-image__title {
    margin-bottom: rem(40);

    @include screen-laptop-and-bigger {
      margin-bottom: rem(60);
    }
  }
}
