.c-explore-link-list {
  padding-top: rem(15);

  @include screen-tablet-portrait-and-bigger {
    padding-top: rem(20);
  }

  .explore-block__column {
      width: initial;

      @include screen-tablet-portrait-and-smaller {
          padding: rem(15) 0 rem(90);
      }

      @include screen-tablet-landscape-and-bigger {
          padding: rem(40) 0 rem(110);
      }

      @include screen-laptop-and-bigger {
          padding: rem(40) 0 rem(197);
      }

      @include screen-desktop-and-bigger {
          padding: rem(64) 0 rem(277) !important;
      }

      @include screen-desktop-plus-and-bigger {
          padding-bottom: rem(440);
      }

      .o-grid {
          position: relative;
          display: flex;
          justify-content: space-between;
      }
  }

  .experience-assets-exploreLink:not(:last-child) {
      margin-bottom: rem(15);

      .c-explore-link {
          border-bottom: rem(1) solid var(--color-black-transparent-25);
      }
  }
}

.c-explore-link-list--no-image {
  @include default-block-space;

  .explore-block__column {
    padding: 0 !important;

    @include screen-desktop-and-bigger {
      padding: 0 !important;
    }
  }

  .explore-link-list__links {
    min-height: initial;
  }

  .explore-link__image {
    display: none;
  }

  .c-explore-link {
      width: 100%;
  }

  .explore-link-list__title {
      font-size: rem(30);

      @include screen-tablet-landscape-and-bigger {
          font-size: rem(55);
      }
  }

  .explore-link__arrow,
  .explore-link__label {
      opacity: 1;
  }
}

.explore-link-list__links {
  @include screen-tablet-landscape-and-bigger {
      min-height: rem(280);
  }

  @include screen-desktop-plus-and-bigger {
      min-height: rem(400);
  }
}

.explore-link-list__title {
  @include screen-desktop-and-bigger {
      width: 60%;
  }
}
