.c-paged-navigation-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: $global-spacing-md $global-spacing-md $global-spacing-lg;

  @include screen-tablet-landscape-and-bigger {
    padding: $global-spacing-lg $global-spacing-lg $global-spacing-md $global-spacing-lg;
  }

  .u-h3 {
    text-transform: initial;
  }
}

.paged-navigation-header__button {
  position: static;
  transform: translate3d(-10px, -2px, 0);

  &.modal__button-close {
    transform: translate3d(10px, -2px, 0);
  }
}
