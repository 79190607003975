@mixin button--primary {
  background-color: var(--color-button-primary-background);
  color: var(--color-button-primary-text);
  font-weight: $font-weight-medium;
  letter-spacing: .2px;
  padding: $button-spacing;
  text-transform: uppercase;

  &:not([disabled]):not(.disabled) {
    @include hover-focus() {
      background-color: var(--color-button-primary-background-hover);
    }

    @include keyboard-focus {
      background-color: var(--color-button-primary-background-focus);
    }
  }

  &[disabled] {
    font-weight: $font-weight-regular;
    background-color: var(--color-button-primary-background-disabled);
    color: var(--color-button-primary-font-disabled);
  }
}
