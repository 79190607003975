.error-page__container {
  display: flex;
  flex-direction: column;
}

.error-page__content {
  .t-black & {
    margin-top: $global-spacing * 3;

    @include screen-tablet-landscape-and-bigger {
      margin-top: $global-spacing * 20;
    }
  }
}

.error-page__heading {
  @include h2;
  margin-bottom: $global-spacing * 2;

  @include screen-tablet-landscape-and-bigger {
    margin-bottom: $global-spacing * 4;
  }
}

.error-page__text {
  @include text-small;
  margin-bottom: $global-spacing * 5;
}

.error-page__socials-heading {
  @include h5;
}

.error-page__socials-icons {
  margin-top: $global-spacing * 4;

  @include screen-tablet-landscape-and-bigger {
    margin-top: $global-spacing * 5;
  }
}

.error-page__img {
  width: 100%;
  margin-bottom: $global-spacing * 8;

  @include screen-tablet-portrait-and-smaller {
    &--touch-sides,
    &--touch-sides[js-hook-objectfit-container] {
      margin-left: -#{map_get($container-gutters, 'xs')};
      width: calc(100% + (#{map_get($container-gutters, 'xs')} * 2));
    }
  }

  @include screen-tablet-landscape-and-bigger {
    margin: 0;

    &--touch-sides {
      margin-left: 0;
      width: 50vw;
      position: absolute;

      + .error-page__img-spacer {
        display: block;
        min-height: 75vh;
      }
    }
  }
}
