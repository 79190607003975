.c-flowbox {
  .bzfy-t-feed-carousel {
    background-color: transparent !important;
  }

  .flowbox__link,
  .flowbox__text {
    color: var(--color-black-transparent-60);
  }

  .flowbox__title {
    @include screen-tablet-portrait-and-bigger {
      line-height: 0.75;
    }
  }

  .flowbox__tag {
    display: none;

    @include screen-tablet-portrait-and-bigger {
      display: flex;
      text-align: right;
      color: var(--color-black-transparent-60);
    }
  }

  .flowbox__tag-link {
    padding: rem(6) rem(12);

    @include screen-tablet-portrait-and-bigger {
      padding-top: 0;
    }
  }

  .flowbox__text {
    padding: 0;
  }
}
