.c-search-suggestions {
  @include screen-tablet-landscape-and-bigger {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    padding-bottom: 100px;
    overflow: hidden;
  }
}

.search-suggestions__result {
  .c-button--primary {
    color: var(--color-button-primary-text) !important;

    @include hover-focus(false) {
      color: var(--color-button-primary-text) !important;
    }
  }
}

.search-suggestions__label {
  font-weight: $font-weight-medium;
}

.search-suggestions__list {
  @include list-reset;
}

.search-suggestions__list-item {
  border-bottom: 1px solid var(--color-divider-line);

  @include screen-tablet-landscape-and-bigger {
    border-bottom: 0;
  }
}

.search-suggestions__link {
  text-decoration: none;
  padding-top: $global-spacing-sm;
  padding-bottom: $global-spacing-sm;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-image: none;

  @include screen-tablet-landscape-and-bigger {
    flex-direction: row-reverse;
    justify-content: flex-end;
  }

  @include hover-focus {
    outline: none;
    color: var(--color-button-ghost-focus-text);

    .image__default {
      transform: scale(1.15);
    }
  }
}

.search-suggestions__link-body {
  line-height: 1.2;
}

.search-suggestions__link-image-wrapper {
  width: 64px;
  //height: 64px;
  flex-shrink: 0;
  margin-left: $global-spacing-lg;
  overflow: hidden;
  background-color: var(--color-background-inverted-10);

  @include screen-tablet-landscape-and-bigger {
    margin-left: 0;
    margin-right: $global-spacing-lg;
  }
}

.search-suggestions__link-image {
  img {
    width: 100%;
    height: 100%;
  }

  .image__default {
    transition: 400ms transform $ease-out-quad, opacity 200ms ease-out;
  }

}

.search__result-title {
  @include h2;

  @include screen-laptop-and-bigger {
    font-size: rem(61);
  }
}
