// Font stacks

$font-stack-primary: 'Avenir', Arial, sans-serif;
$font-stack-secondary: 'Avenir', Arial, sans-serif;

// Font settings

$font-stack-body: $font-stack-primary;
$font-stack-buttons: $font-stack-secondary;

// Font sizes

$font-size-root-px: 10px; // The font size set on the root html element.
$font-size-root: 62.5%; // The font size set on the root html element.
$font-size-small: 1.2rem;
$font-size-medium: 1.4rem; // Medium font size
$font-size-body: 1.6rem; // Regular font size
$font-size-input: $font-size-body; // Input font size

$font-line-height-body: 1.65; // {design-line-height} / strip-unit($font-size-body);

// Font size sets
$font-size-set-h1: (
  'xs': 4rem,
  'xxlg': 8rem,
);

$font-size-set-h1-secondary: (
  'xs': 4rem,
  'xxlg': 8rem,
);

$font-size-set-h2: (
  'xs': 4rem,
  'xxlg': 6.4rem,
);

$font-size-set-h2-secondary: (
  'xs': 4rem,
  'xxlg': 6.4rem,
);

$font-size-set-h3: (
  'xs': 4rem,
  'xxlg': 4rem,
);

$font-size-set-h3-secondary: (
  'xs': 4rem,
  'xxlg': 4rem,
);

$font-size-set-h4: (
  'xs': 2.4rem,
  'xxlg': 2.5rem,
);

$font-size-set-h5: (
  'xs': 1.6rem,
  'xxlg': 1.6rem,
);

$font-size-set-h5-large: (
  'xs': 1.6rem,
  'xxlg': 2.4rem,
);

$font-size-set-h6: (
  'xs': 1.4rem,
  'xxlg': 1.4rem,
);

$font-size-set-p-large: (
  'xs': 2rem,
  'xxlg': 2.4rem,
);

$font-size-set-text: 1.6rem;

$font-size-set-text-small: 1.4rem;

$font-size-set-text-xsmall: 1.2rem;

$font-size-set-text-large: 2rem;

// Font line height sets
// Divide the the given line height by the font size to give an unitless line height
// @example {design line height} / {font size} = {unitless line height}
//
// NOTE: Never use px as line-height value!!!

$line-height-body: 24 / 16;

$line-height-set-h1: (
  'xs': 48 / 40,
  'md': 88 / 80,
);

$line-height-set-h1-secondary: (
  'xs': 48 / 40,
  'md': 88 / 80,
);

$line-height-set-h2: (
  'xs': 48 / 40,
  'md': 64 / 64,
);

$line-height-set-h2-secondary: (
  'xs': 48 / 40,
  'md': 64 / 64,
);

$line-height-set-h3: (
  'xs': 48 / 40,
  'md': 48 / 40,
);

$line-height-set-h3-secondary: (
  'xs': 48 / 40,
  'md': 48 / 40,
);

$line-height-set-h4: (
  'xs': 28 / 24,
  'md': 27 / 25,
);

$line-height-set-h5: (
  'xs': 32 / 24,
  'md': 40 / 32,
);

$line-height-p-large: (
  'xs': 28 / 20,
  'md': 32 / 24,
);

$line-height-h6: 1;

$line-height-set-text: 22 / 16;

$line-height-set-text-small: 20 / 14;

$line-height-set-text-xsmall: 12 / 10;

$line-height-set-text-large: 28 / 20;

// Font weights

$font-weight-regular: 400;

$font-weight-semi-medium: 550;

$font-weight-medium: 600;

$font-weight-extrabold: 700;

$font-weight-bold: 800;
