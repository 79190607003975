.c-search {
  //@include screen-tablet-landscape-and-smaller {
  //  flex: 0 0 140px;
  //  transition: 300ms ease flex;
  //
  //  .search--is-open & {
  //    flex: 1 0 100%;
  //  }
  //}
}

.search__form-wrapper {
  display: flex;
  justify-content: space-between;
  position: absolute;
  width: calc(100% + #{$global-spacing-sm});
  height: calc(100% - 2px);
  top: 0;
  left: -$global-spacing;
  transform: translate3d(0, -200%, 0);
  opacity: 0;
  pointer-events: none;
  transition: transform 0ms ease 300ms, opacity 300ms;
  @include z(headerSearchForm);
  background-color: var(--color-background-html);
  @if variable-exists('show-body-background-image') {
    @include background-grain;
  }

  &.is--active {
    transform: translate3d(0, 0, 0);
    pointer-events: auto;
    opacity: 1;
    transition: transform 300ms $ease-out-quad, opacity 200ms;
  }
}

.search__form {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;

  @include screen-tablet-portrait-and-smaller {
    .c-loader {
      @include z(search);
    }
  }

  .form__item {
    width: 100%;
    margin-bottom: 0;
  }

  .input__wrapper {
    display: flex;
    width: 100%;
    padding-right: $global-spacing-sm;
  }

  .c-button[type="submit"] {
    width: auto;
  }
}

.search__button-clear  {
  font-weight: $font-weight-regular;
}

.search__button-submit  {
  width: auto;
  margin-right: $global-spacing;
}

//.search__button-close {}
