.c-hotspot {
  position: absolute;
  // top & left set in isml
}

.hotspot__icon {
  position: relative;
}

// Icon variants
$hotspot-icon-shoppingbag-circle-size: rem(30);
.hotspot__icon--shoppingbag {
  color: $color-white;

  svg {
    position: relative;
    top: rem(-2);

    rect {
      fill: transparent;
    }
  }

  &::before {
    content: '';
    position: absolute;
    display: block;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    border-radius: 50%;
    background-color: rgba($color-white, 0.5);
    width: $hotspot-icon-shoppingbag-circle-size;
    height: $hotspot-icon-shoppingbag-circle-size;
    transition: transform 200ms ease-in-out;
  }

  @include hover-focus {
    &::before {
      animation: focus-pulse-grow 1500ms $ease-in-out-circ infinite;
    }
  }
}

$hotspot-icon-circle-border-size: rem(22);
$hotspot-icon-circle-circle-size: rem(17);
.hotspot__icon--circle {
  &::after,
  &::before {
    content: '';
    position: absolute;
    display: block;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    border-radius: 50%;
  }

  &::after {
    width: $hotspot-icon-circle-border-size;
    height: $hotspot-icon-circle-border-size;
    border: 1px solid $color-white;
    transition: transform 200ms ease-in-out;
  }

  &::before {
    width: $hotspot-icon-circle-circle-size;
    height: $hotspot-icon-circle-circle-size;
    background-color: $color-white;
  }

  @include hover-focus {
    &::after {
      animation: focus-pulse-grow 1500ms $ease-in-out-circ infinite;
    }
  }
}
