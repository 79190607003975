$quote-author-image-size: 55px;
$quote-author-spacing: $global-spacing-xlg;

.c-quote {
  @include default-pd-asset-space;
  display: block;
}

.quote__text-container {
  padding-bottom: $global-spacing-md;
}

.quote__author-wrapper {
  position: relative;
  padding-top: $quote-author-spacing;
  border-top: 1px solid var(--color-divider-line);
}

.quote__author-image {
  position: absolute;
  left: 0;
  top: $quote-author-spacing + 2px;
  width: $quote-author-image-size;
  height: 40px;
  display: flex;
  justify-content: center;
}

.quote__author-name {
  font-weight: $font-weight-bold;
  margin-left: $quote-author-image-size + $global-spacing-sm;
}

.quote__author-text {
  display: block;
  margin-left: $quote-author-image-size + $global-spacing-sm;
}
