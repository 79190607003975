.c-hero {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: $global-spacing-xlg;
  height: var(--hero-image-height-mobile);

  @include screen-tablet-landscape-and-bigger {
    height: var(--hero-image-height-desktop);
  }

  .o-container--bottom {
    position: relative;
    margin-top: auto;
    bottom: $global-spacing-sm;
  }

  .o-grid {
    position: relative;
    z-index: 2;
  }

  // text color overrides for black theme
  .t-black & {
    color: var(--color-text-inverted);

    &.s-white {
      color: var(--color-text);
    }
  }
}

.hero--fullheight {
  height: 80vh;

  @include screen-tablet-landscape-and-bigger {
    height: 100vh;
  }
}

.hero--has-link {
  @include hover-focus {
    .hero__link-arrow {
      transform: none;
      opacity: 1;
    }
  }
}

.hero__title {
  @include screen-tablet-landscape-and-bigger {
    width: 50%;
  }
}

.hero__title--wider {
  width: 90%;

  @include screen-tablet-landscape-and-bigger {
    width: 35%;
  }
}

.hero__subtitle {
  @include text-xsmall;
  font-weight: $font-weight-medium;
}

.hero__link {
  margin-top: $global-spacing-md;
}

.hero__link-arrow {
  margin-left: $global-spacing;
  opacity: 0;
  transform: translate3d(-$global-spacing, 0, 0);
  transition: transform 250ms $ease-out-quad, opacity 200ms;

  html.is-touch & {
    opacity: 1;
    transform: none;
  }
}

.hero__button {
  @include text-small;
  font-weight: $font-weight-medium;
  text-decoration: none;
  border: 1px solid $color-white;
  padding: $button-spacing;
  text-transform: uppercase;
}

.hero__button--hover {
  &:hover {
    background-color: $color-white;
    color: $color-black;
  }
}

.hero__video {
  margin-bottom: 0;
  padding-bottom: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;

  .video__player {
    /* Creates the same effect as applying object fit: cover,
       which does not work on an iframe */
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100vw;
    transform: translate(-50%, -50%);
    background-color: $color-black;

    @media (min-aspect-ratio: 16/9) {
      height: 56.25vw;
    }

    @media (max-aspect-ratio: 16/9) {
      width: 177.78vh;
    }
  }
}

.hero__media-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.hero__media {
  height: 100%;
  position: relative;
  margin: auto;

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(111.47% 178.39% at 53.23% 67.45%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.44) 99.99%, rgba(0, 0, 0, 0.44) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
  }
}

.hero__media--min-width {
  min-width: calc(100% - #{map_get($container-gutters, 'sm') * 2});

  @include screen-min-width($breakpoint-tablet-landscape) {
    min-width: calc(100% - #{map_get($container-gutters, 'md') * 2});
  }

  @include screen-min-width($breakpoint-laptop) {
    min-width: calc(#{$breakpoint-laptop - (map_get($container-gutters, 'md') * 2)});
  }
}

.hero-view-count {
    position: fixed;
    top: calc(var(--hero-image-height-mobile) + 30px);
    right: 20px;
    color: $color-white;
    z-index: 10;

    @include screen-tablet-landscape-and-bigger {
        right: 40px;
        top: m#{i}n(calc(var(--hero-image-height-desktop) - 60px), 70%);
    }

    &__card {
        max-width: 142px;
        padding: $global-spacing-xlg / 8 $global-spacing-xxxxlg / 8;
        background-color: $color-black;

        @include screen-tablet-landscape-and-bigger {
            text-align: center;
            max-width: 160px;
        }

        svg {
            margin-left: 40px;
            vertical-align: text-bottom;

            @include screen-tablet-landscape-and-bigger {
                margin-left: 0;
                margin-bottom: $global-spacing-xlg / 8;
            }
        }

        h2 {
            @include h4;
            display: inline-block;

            @include screen-tablet-landscape-and-bigger {
                @include h2;
                display: block;
                font-weight: $font-weight-medium;
                line-height: 1 !important;
                transform: none !important;
            }
        }

        p {
            font-size: .9rem; //only used once
            margin-bottom: 0;

            @include screen-tablet-landscape-and-bigger {
                @include text-small;
            }
        }
    }
}

.hero__clickarea {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-image: none;
}
