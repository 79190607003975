.c-basic-text {
  @include default-pd-asset-space;

  > *:last-child {
    margin-bottom: 0;
  }
}

.basic-text__pretitle {
  display: block;
  margin-bottom: $global-spacing-xs;
}

.basic-text__title {
  @include h3-secondary;
  transform: none;
  margin-bottom: $global-spacing-md;
}

.basic-text__subtitle {
  @include h4;
  transform: none;
  margin-bottom: $global-spacing-md;
}

.basic-text__element {
  margin-bottom: $global-spacing-lg;

  &--button {
    margin-bottom: $global-spacing-md;

    .basic-text--right-align-buttons & {
      @include screen-tablet-landscape-and-bigger {
        text-align: right;
      }
    }
  }
}

.basic-text__intro {
  @include h5-large;
  font-weight: $font-weight-medium;
  text-transform: none;
  margin-bottom: $global-spacing-md;
}

.disabled {
  opacity: 0.4;
  overflow: hidden;
  pointer-events: none;
}
.privacy-block {
  margin-bottom: $global-spacing-md;
}

.content-spot {
  .basic-text__line {
    background-color: $color-black;
    margin-bottom: $global-spacing-lg;
  }

  .basic-text__title {
    margin-bottom: $global-spacing-sm;
  }

  .basic-text__subtitle {
    margin-bottom: $global-spacing-md;
  }

  .basic-text__body {
    width: 75%;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin-bottom: $global-spacing-lg !important;

    @include screen-mobile-plus-and-smaller {
      width: 100%;
      margin-bottom: $global-spacing-md !important;
    }
  }

  .basic-text__image {
    height: auto;
    margin-bottom: 16px;

    img {
      max-height: 28rem !important;
    }
  }
}
