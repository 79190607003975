$grid-gutter-small-breakpoint: $breakpoint-mobile-plus;
$grid-breakpoints: (
  'xs': $breakpoint-mobile,
  'sm': $breakpoint-tablet-portrait,
  'md': $breakpoint-tablet-landscape,
  'lg': $breakpoint-laptop,
  'xl': $breakpoint-desktop,
  'xxl': $breakpoint-desktop-plus,
);

$grid-gutters: (
  'xs': 8px,
  'sm': 8px,
  'md': 16px,
  'lg': 16px,
  'xl': 16px,
  'xxl': 16px,
);

$container-gutters: (
  'xs': 24px,
  'sm': 24px,
  'md': 40px,
  'lg': 40px,
  'xl': 40px,
  'xxl': 40px,
);

$grid-config: (
  max-width: $breakpoint-laptop,
  columns: 24,
  grid-gutter: $grid-gutters,
  container-gutter: $container-gutters,
  add-push-pull: true,
  add-pre-post: true,
);
