// On a black background these colors are allowed for text:
// white

.t-black,
.background-updated {
  // Core color settings
  --color-primary: #{$color-white};
  --color-background-html: #{$color-black};
  --color-background: #{$color-black};
  --color-background-html-0: #{rgba($color-black, 0)};
  --color-background-inverted-10: #{rgba($color-white, .1)};
  --color-background-html-50: #{$color-black-transparent-50};
  --color-background-mega-menu: #{$color-black};
  --color-background-footer-bottom: #{$color-black-grey};
  --color-text: #{$color-white};
  --color-text-dark: #{$color-white};
  --color-text-light: #{$color-white};
  --color-text-inverted: #{$color-black};
  --color-text-inverted-70: #{rgba($color-black, .7)};
  --color-text-footer: var(--color-text);
  --color-text-footer-top: #{$color-white};
  --color-definition-table: #{$color-white};
  --color-definition-table-title: #{$color-white};
  --color-divider-line: #{$color-white-transparent-20};
  --color-slider-indicator-line: #{$color-white};
  --color-slider-status-line: #{$color-white-transparent-20};
  --color-black-transparent-25: #{$color-white};
  --color-black-transparent-60: #{$color-white};

  // Core background settings see stylesheet.isml

  // Button colors
  // - Primary
  --color-button-primary-background: #{$color-white};
  --color-button-primary-text: #{$color-black};
  --color-button-primary-background-hover: #{$color-white-transparent-75};
  --color-button-primary-background-focus: #{$color-grey};
  --color-button-primary-background-disabled: #{$color-off-black};
  --color-button-primary-font-disabled: #{$color-tinted-grey};

  // - Ghost
  --color-button-ghost: #{$color-white};
  --color-button-ghost-underline: #{$color-white-transparent-20};
  --color-button-ghost-hover: #{$color-dark-grey};
  --color-button-ghost-hover-text: #{$color-white};
  --color-button-ghost-hover-icon-only: #{$color-white-transparent-50};
  --color-button-ghost-focus: #{$color-dark-grey};
  --color-button-ghost-focus-text: #{$color-white};
  --color-button-ghost-focus-background: #{$color-black-grey};
  --color-button-ghost-disabled: #{$color-black-grey};
  --color-button-ghost-disabled-text: #{$color-white};

  // Form Colors
  --color-form-item-placeholder: #{$color-white};
  --color-form-item-default: #{$color-tinted-grey};
  --color-form-item-hover: #{$color-white};
  --color-form-item-hover-text: #{$color-white};
  --color-form-item-focus: #{$color-white};
  --color-form-item-focus-text: #{$color-white};
  --color-form-item-disabled: #{$color-dark-grey};
  --color-form-item-disabled-text: #{$color-white};
  --form-checkbox-icon: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMSwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxMCA2IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxMCA2OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6I0ZGRkZGRjt9Cjwvc3R5bGU+Cjxwb2x5Z29uIGNsYXNzPSJzdDAiIHBvaW50cz0iOS4zLDAgMy42LDQuOCAwLjcsMi40IDAsMyAzLjYsNiAxMCwwLjcgIi8+Cjwvc3ZnPgo=');

  // Invert
  --arco-invert: invert(1);
}

.background-animation--on {
  --color-black-transparent-25: #{$color-white};
  --color-black-transparent-60: #{$color-white};
  --color-divider-line: #{$color-white};
}
