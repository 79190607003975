//
//    Set the spacing for each viewport
//
//    @see: utilities/utilities.spacing
//
//    @example: .u-margin-top--xs
//
//

// TODO: Deprecate old spacing sizes

$global-spacing: 8px;
$global-spacing-xxs: $global-spacing / 2;
$global-spacing-xs: $global-spacing;
$global-spacing-sm: $global-spacing * 2;
$global-spacing-md: $global-spacing * 3;
$global-spacing-lg: $global-spacing * 4;
$global-spacing-xlg: $global-spacing * 5;
$global-spacing-xxlg: $global-spacing * 6;

$global-spacing-xxxlg: $global-spacing * 8;
$global-spacing-xxxxlg: $global-spacing * 10;

$spacing-sizes: (
  // Spacing modifier
  // .u-margin-top--{ modifier }
  'xlg':
    (
      // Breakpoint triggers
      'xxxlg': $global-spacing * 8,
      'xxlg': $global-spacing * 8,
      'xlg': $global-spacing * 8,
      'lg': $global-spacing * 4,
      'md': $global-spacing * 3,
      'sm': $global-spacing * 3,
      'xs': $global-spacing * 3
    ),
  'lg': (
    'xxxlg': $global-spacing * 6,
    'xxlg': $global-spacing * 6,
    'xlg': $global-spacing * 6,
    'lg': $global-spacing * 4,
    'md': $global-spacing * 2,
    'sm': $global-spacing * 2,
    'xs': $global-spacing * 2,
  ),
  'md': (
    'xxxlg': $global-spacing * 4,
    'xxlg': $global-spacing * 4,
    'xlg': $global-spacing * 4,
    'lg': $global-spacing * 4,
    'md': $global-spacing * 2,
    'sm': $global-spacing * 2,
    'xs': $global-spacing * 2,
  ),
  'sm': $global-spacing * 2,
  'xs': $global-spacing,
  'xxs': $global-spacing / 2
);

$spacing-sizes-numeral: (
  0: 0,
  4: $global-spacing / 2,
  8: $global-spacing,
  16: $global-spacing * 2,
  24: $global-spacing * 3,
  32: $global-spacing * 4,
  40: $global-spacing * 5,
  48: $global-spacing * 6,
  56: $global-spacing * 7,
  64: $global-spacing * 8,
  72: $global-spacing * 9,
  80: $global-spacing * 10,
  120: $global-spacing * 15,
  160: $global-spacing * 20,
  200: $global-spacing * 25,
);

$breakpoint-list-up: (
    'xxxlg': $screen-desktop-plus-and-bigger,
    'xxlg': $screen-desktop-and-bigger,
    'xlg': $screen-laptop-and-bigger,
    'lg': $screen-tablet-landscape-and-bigger,
    'md': $screen-tablet-portrait-and-bigger,
    'sm': $screen-mobile-plus-and-bigger,
    'xs': $screen-mobile-and-bigger,
);
