.c-country-gateway {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}

#modal-country-gateway {
  @include screen-tablet-portrait-and-smaller {
    opacity: 1;
    visibility: visible;
    top: auto;
    right: 0;
    bottom: -100%;
    left: 0;
    transition: 800ms ease-out;
  }

  .modal__container {
    @include screen-tablet-portrait-and-smaller {
      padding: 0;
    }
  }

  .modal__content {
    max-width: rem(900);
    height: rem(480);
    max-height: rem(480);
    padding: 0;

    @include screen-tablet-portrait-and-smaller {
      max-width: 100%;
      height: auto;
      opacity: 1;
      visibility: visible;
      position: absolute;
      bottom: 0;
    }
  }

  .modal__button-close {
    top: rem(20);
    right: rem(18);
  
    &:focus-visible {
      outline: none;
    }
  }
}

#modal-country-gateway.modal--is-showing {
  @include screen-tablet-portrait-and-smaller {
    bottom: 0;
    transition: 400ms ease-out;
  }
}

.country-gateway__content {
  height: 100%;
  display: grid;
  grid-template-columns: 30.8rem calc(100% - 30.8rem);

  @include screen-tablet-portrait-and-smaller {
    grid-template-columns: 100%;
  }

  .country-gateway__options-wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    padding: 0 rem(36);
    position: relative;

    .country-gateway__title {
      margin: rem(66) 0 0 0;
      font-size: 24px;
      font-weight: 750;
      line-height: 32px;
    }

    .country-gateway__options {
      margin-top: rem(35);

      @include screen-tablet-portrait-and-smaller {
        margin-top: rem(24);
      }
    }

    .country-gateway__bar--bottom {
      margin: rem(40) 0 rem(18) 0;

      @include screen-tablet-portrait-and-smaller {
        margin: rem(28) 0 rem(38) 0;
      }

      hr {
        @include screen-tablet-portrait-and-smaller {
          display: none;
        }
      }

      .country-gateway__bar-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: rem(26);

        @include screen-tablet-portrait-and-smaller {
          margin-top: 0;
        }

        .c-button {
          margin-bottom: 0;
        }
      }
    }
  }

  .csb__list {
    max-height: rem(200);
    overflow: auto;
  }
}

.country-gateway__image-wrapper {
  padding: 0;

  .content-asset {
    width: 100%;
    height: 100%;

    .country-gateway__image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  @include screen-tablet-portrait-and-smaller {
    display: none;
  }
}

.country-gateway__item-label {
  display: block;
  font-size: 16px;
  font-weight: 750;
  line-height: 20px;
  text-transform: none;
}

.country-selector-button {
  @include screen-tablet-portrait-and-smaller {
    width: 100%;
  }
}

.country-gateway__bar-content-description {
  font-weight: 400;
  line-height: 20px;

  @include screen-tablet-portrait-and-smaller {
    font-size: 14px;
  }
}

.country-selector-button[disabled] {
  background-color: var(--color-button-primary-background-disabled);
  color: var(--color-button-primary-font-disabled);
  cursor: not-allowed;
  pointer-events: none;
}
