// Color palette
$color-warm-red: #a64f36;
$color-warm-red-light: #b5593f;
$color-moss-green: #70786a;
$color-moss-green-light: #848b7f; //only used for page background color due to the grain overlay
$color-blue-sky: #5d7796;
$color-blue-sky-light: #7691b0; //only used for page background color due to the grain overlay
$color-blue: #019495;

$color-black: #000;
$color-off-black: #2d2d2d;
$color-black-grey: #3d3d3d;
$color-dark-grey: #666;
$color-tinted-grey: #999;
$color-grey: #d9d9d9;
$color-light-grey: #e5e5e5;
$color-white-grey: #fafafa;
$color-off-white: #f9f9f9;
$color-red: #B83C3C;
$color-web-gray: #757F7F;

$color-black-transparent-8: rgba(0, 0, 0, 0.08);
$color-black-transparent-15: rgba(0, 0, 0, 0.15);
$color-black-transparent-25: rgba(0, 0, 0, 0.25);
$color-black-transparent-40: rgba(0, 0, 0, 0.4);
$color-black-transparent-50: rgba(0, 0, 0, 0.5);
$color-black-transparent-60: rgba(0, 0, 0, 0.6);
$color-black-transparent-75: rgba(0, 0, 0, 0.75);

$color-white-transparent-50: rgba(255, 255, 255, 0.5);
$color-white-transparent-75: rgba(255, 255, 255, 0.75);
$color-white-transparent-20: rgba(255, 255, 255, 0.2);

$color-white: #fff;
$color-beige: #f4efe7;

// If var color-background-section exists
// PD layouts with background turned on will have a background color full width

// If var $color-background-column-section exists
// PD layouts with background turned on will have a background color the width of the container
$show-column-background-section-color: true;

// If var $show-body-background-image exists
// background-grain mixin is added to body and other elements
$show-body-background-image: true;

// Colors that need override on dark theme pages
// Defined in theme files
//// Button colors
//// Core color settings
//// Form color settings
//// States

// Social color palette
$color-social-twitter: #00aced;
$color-social-facebook: #3b5998;
$color-social-googleplus: #dd4b39;
$color-social-pinterest: #cb2027;
$color-social-linkedin: #007bb6;
$color-social-youtube: #b00;
$color-social-vimeo: #aad450;
$color-social-instagram: #517fa4;
$color-social-flickr: #ff0084;
$color-social-dribbble: #ea4c89;
$color-social-whatsapp: #4dc247;

// List of social channels with their colors you can use to loop through
$color-socials-map: (
  'twitter': $color-social-twitter,
  'facebook': $color-social-facebook,
  'linkedin': $color-social-linkedin,
  'youtube': $color-social-youtube,
);

// Color maps to loop through
$colors-map: (
  'warm-red': $color-warm-red,
  'warm-red-light': $color-warm-red-light,
  'moss-green': $color-moss-green,
  'moss-green-light': $color-moss-green-light,
  'blue-sky': $color-blue-sky,
  'blue-sky-light': $color-blue-sky-light,
  'black': $color-black,
  'off-black': $color-off-black,
  'black-grey': $color-black-grey,
  'dark-grey': $color-dark-grey,
  'tinted-grey': $color-tinted-grey,
  'grey': $color-grey,
  'light-grey': $color-light-grey,
  'white-grey': $color-white-grey,
  'white': $color-white,
  'beige': $color-beige,
);

$color-swatches-map: (
  'beige': #e4d2c5,
  'blue': #283870,
  'blond': #faf0be,
  'bordeaux': #5f021f,
  'bronze': #cd7f32,
  'brown': #964b00,
  'creme': #fffdd0,
  'yellow': #f7ce46,
  'gold': #c9ac48,
  'grey': #808080,
  'green': #367b3d,
  'copper': #8e402a,
  'mauve': #b784a7,
  'miscellaneous': transparent,
  'nude': #efe8dc,
  'orange': #e99d56,
  'purple': #853b92,
  'peach': #ffdab9,
  'red': #a22a22,
  'pink': #ffc0cb,
  'taupe': #b38b6d,
  'transparant': #fff,
  'turquoise': #40e0d0,
  'mint': #0ff,
  'navy': #000080,
  'white': #fff,
  'silver': #c0c0c0,
  'black': #000,
);

$color-text-map: (
  'white': $color-white,
  'black': $color-black,
  'black-grey': $color-black-grey,
  'tinted-grey': $color-tinted-grey,
  'dark-grey': $color-dark-grey,
);

$color-backgrounds-map: (
  'white': $color-white,
  'black': $color-black,
  'warm-red': $color-warm-red-light,
  'moss-green': $color-moss-green-light,
  'blue-sky': $color-blue-sky-light,
);
