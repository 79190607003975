.c-store-locator {
  height: 100%;
  display: flex;
  flex-direction: column;

  .input__cta {
    bottom: 0;
    right: 0;
    padding: $global-spacing;
    height: 100%;
  }
}

.store-locator__header {
  @include screen-tablet-landscape-and-bigger {
    margin-bottom: 0;
  }
}

.store-locator__result {
  display: flex;
  flex-direction: column;
}

.store-locator__result-title {
  display: block;
  font-size: $font-size-body;
}

.store-locator__result-nearest {
  display: none;

  @include screen-tablet-landscape-and-bigger {
    display: block;
    margin-bottom: $global-spacing * 4;
    line-height: $line-height-set-text;
    color: $color-tinted-grey;
  }
}

.store-locator__location-not-found {
  display: block;
}

.store-locator__result-title--hidden,
.store-locator__result-nearest--hidden,
.store-locator__location-not-found--hidden {
  display: none !important;
}

.store-locator__title {
  @include text;
}

.store-locator__content {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.store-locator__sidebar {
  @include screen-tablet-landscape-and-bigger {
    display: flex;
    flex-direction: column;
  }
}

.store-locator__sidebar--map-is-active {
  visibility: visible !important;
}

.store-locator__form {
  @include screen-tablet-landscape-and-bigger {
    width: 50%;
    position: relative;
    z-index: 2;
  }
}

.store-locator__form-input {
  display: flex;
  align-items: center;
  margin: $global-spacing-xxxxlg / 4 0 $global-spacing-xxxlg;

  @include screen-tablet-landscape-and-bigger {
    margin: $global-spacing * 4 0 0;
  }

  img {
    cursor: pointer;
  }

  .form__item {
    width: 100%;
    margin: 0 $global-spacing-sm 0 0;
  }

  .input__input {
    line-height: $line-height-set-text;
    background-color: transparent !important;
    color: $color-black !important;
  }
}

.store-locator__form-input--address {
  flex: 1 0 65%;

  @include screen-tablet-portrait-and-bigger {
    flex: 1;
  }
}

.store-locator__amount-found {
  color: $color-black;
  margin-bottom: $global-spacing * 4;

  .c-modal & {
    display: none;
  }
}

.store-locator__results-row {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  position: relative;

  @include screen-tablet-landscape-and-bigger {
    flex-direction: row;
  }
}

.store-locator__results {
  overflow: auto;
  height: 100%;
  background-color: $color-white;
}

.store-locator__tabs {
  margin-bottom: 0;

  .tabs__nav {
    padding: 0;

    &::before {
      content: initial;
    }
  }

  .tabs__nav-item:first-child {
    margin: 0 $global-spacing / 2 0 0;
  }

  .tabs__nav-button {
    padding: $global-spacing * 1.75 $global-spacing * 2;
    cursor: pointer;

    &:not(.tabs__nav-button--is-active) {
      opacity: 1;

      @include hover-focus(false) {
        color: $color-white;
      }
    }
  }

  .tabs__nav-button--is-active {
    box-shadow: initial;
  }
}

.store-locator__tabpanel {
  visibility: hidden;
  position: relative;
  width: 100%;
  height: 100%;

  &.is--active {
    visibility: visible;
    z-index: 1;
  }

  &.is--active-desktop {
    visibility: visible;
    z-index: 1;

    @include screen-tablet-landscape-and-bigger {
      position: static;
    }
  }
}

.store-locator__no-results,
.store-locator__location-not-found {
  @include h6;
  font-weight: $font-weight-bold;
}

.store-locator__store {
  border-bottom: 1px solid $color-tinted-grey;
}

.store-locator__map-wrapper {
  height: 675px;
  flex: 1 0 100%;

  @include screen-tablet-landscape-and-bigger {
    position: sticky !important;
    top: 5%;
    flex: 1 0 63%;
  }
}

.store-locator__map-selected-store {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: $color-white;
  padding: $global-spacing * 2 $global-spacing;

  @include screen-tablet-landscape-and-bigger {
    display: none;
  }

  .store__hours {
    display: none;

    &.is--expanded {
      display: flex;
    }
  }
}

.c-store-locator--page {
  .accordion__item:last-child {
    border: 0;
  }

  .accordion__item--is-active {
    border-top: 0;
  }

  .store-locator__map-selected-store {
    position: absolute;
  }

  .store-locator__form {
    margin-bottom: 0;
    width: 100%;
    display: flex;
  }

  .store-locator__form-row {
    display: flex;
    flex-wrap: wrap;
  }

  .store-locator__amount-found {
    margin-bottom: 0;
    display: flex;
    align-items: center;
  }

  .store-locator__stores-found-text {
    margin-left: $global-spacing / 2;
  }

  .store-locator__form-input--address {
    margin-left: 0;
  }

  .store-locator__results--map-is-active {
    display: none;

    @include screen-tablet-landscape-and-bigger {
      display: block;
    }
  }
}

.c-online-retailers {
  margin-top: $global-spacing * 8.5;
  // margin-bottom: $global-spacing * 9;

  button {
    display: block;
  }

  .store__links {
    display: none;
  }

  .store__infos {
    display: none;
  }

  .store__info-store-type {
    display: none;
  }

  svg {
    height: 12px;
    width: 12px;
  }

  .c-store {
    @include screen-tablet-landscape-and-bigger {
      padding: $global-spacing * 2.5 0;
    }
  }

  @include screen-tablet-landscape-and-bigger {
    margin-top: $global-spacing * 12;
    // margin-bottom: $global-spacing * 4.125;
  }

  .online-retailers__header {
    @include fluid-type(6.4rem, 3.2rem);
    margin-bottom: $global-spacing * 4.25;
    font-weight: $font-weight-medium + 100;
    @include screen-tablet-landscape-and-bigger {
      margin-bottom: 0;
    }
  }
}

.online-retailers__sites {
  @include screen-tablet-landscape-and-bigger {
    align-self: flex-end;
  }
}
