// On a warm red background these colors are allowed for text:
// black

.t-warm-red {
  // Core color settings
  --color-text-light: #{$color-black};
  --color-background-html: #{$color-warm-red-light};
  --color-background-html-0: #{rgba($color-warm-red-light, 0)};
  --color-background-html-50: #{rgba($color-warm-red-light, .5)};
  --color-background-mega-menu: #{$color-warm-red-light};
  --color-background: #{$color-warm-red-light};
  --color-background-inverted-10: #{rgba($color-white, .1)};
  --color-divider-line: #{$color-black-transparent-40};
  --color-definition-table: #{$color-black};
  --color-definition-table-title: #{$color-black};

  // Core background settings see stylesheet.isml

  // Button colors overrides
  // - Primary
  --color-button-primary-background-disabled: #{$color-off-black};
  --color-button-primary-font-disabled: #{$color-tinted-grey};

  // - Ghost
  --color-button-ghost: #{$color-black};
  --color-button-ghost-underline: #{$color-black-transparent-40};
  --color-button-ghost-hover: #{$color-black-grey};
  --color-button-ghost-hover-text: #{$color-black};
  --color-button-ghost-hover-icon-only: #{$color-black};
  --color-button-ghost-focus: #{$color-black};
  --color-button-ghost-focus-text: #{$color-black};
  --color-button-ghost-focus-background: #{$color-dark-grey};
  --color-button-ghost-disabled: #{$color-black-grey};
  --color-button-ghost-disabled-text: #{$color-black};

  // Form Colors
  --color-form-item-placeholder: #{$color-black};
  --color-form-item-default: #{$color-black-grey};
  --color-form-item-hover: #{$color-black};
  --color-form-item-hover-text: #{$color-black};
  --color-form-item-focus: #{$color-black};
  --color-form-item-focus-text: #{$color-black};
  --color-form-item-disabled: #{$color-black-grey};
  --color-form-item-disabled-text: #{$color-black};
}
