.c-gift-banner__grid {
  position: relative;
}

.c-gift-banner__content-wrapper {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 100%;
  padding: 0 40% 0 rem(20);
  color: $color-white;

  @include screen-tablet-portrait-and-bigger {
    padding: 0 rem(110);
  }
}

.banner__clickarea {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-image: none;
}
