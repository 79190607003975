// @shelf-version 2.0.0
$cookie-transition-time: 400ms !default;
$cookie-transition-ease: $ease-in-out-quad !default;

body.cookie-bar--is-visible {
  overflow: hidden;

  .is-touch & {
    position: fixed;
  }
}

.c-cookie-bar {
  outline: none;
  position: fixed;
  @include z(cookies);
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($color-black, .5);
  visibility: hidden;
  display: flex;
  align-items: flex-end;
  opacity: 0;
  transition: opacity 400ms ease, visibility 0s ease 400ms;

  .cookie-bar--is-visible & {
    opacity: 1;
    visibility: visible;
    transition: opacity 400ms ease, visibility 0s ease;
  }
}

.cookie-bar__content {
  width: 100%;
  padding-top: $global-spacing-md;
  padding-bottom: $global-spacing-md;
  max-height: calc(var(--vh, 1vh) * 100);
  overflow-y: auto;
  background-color: var(--color-background-html);
  box-shadow: 0 0 20px 0 rgba($color-black, .05);
  transform: translate3d(0, 100%, 0);
  transition: transform $cookie-transition-time $cookie-transition-ease;

  .cookie-bar--is-visible & {
    transform: translate3d(0, 0, 0);
  }

  @include screen-tablet-landscape-and-bigger {
    padding-top: $global-spacing-lg;
    padding-bottom: $global-spacing-lg;
  }
}

.cookie-bar__text {
  @include screen-tablet-portrait-and-bigger {
    margin-right: $global-spacing-xxlg;
  }
}

.cookie-bar__button-holder {
  display: flex;

  @include screen-mobile-plus-and-smaller {
    margin-top: $global-spacing-lg;
    margin-left: -10px;
    margin-right: -10px;
  }

  @include screen-tablet-portrait-and-bigger {
    flex-direction: column;
    align-items: flex-end;
    height: 100%;
    justify-content: flex-end;
  }

  .c-button {
    display: block;

    @include screen-mobile-plus-and-smaller {
      width: calc(50% - 20px);
      margin-left: 10px;
      margin-right: 10px;
    }

    @include screen-tablet-portrait-and-bigger {
      margin-top: $global-spacing-md;
      width: 100%;
    }
  }
}
