.c-refinement-options {
  padding-top: 20px;

  @include screen-tablet-landscape-and-bigger {
    padding-top: 28px;
  }
}

.refinement-options__option {
  margin-bottom: 20px;
  width: 100%;

  .radio__label,
  .checkbox__label {
    padding-left: 34px;
  }

  .radio__text,
  .checkbox__text {
    color: var(--color-text);
  }

  &.checked {
    .radio__label,
    .checkbox__label {
      font-weight: $font-weight-bold;
    }
  }
}
