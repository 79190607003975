$video-button-icon-width: 18px;
$video-button-icon-height: 22px;
$video-button-size: 56px;
$video-button-size-mobile: 40px;
$video-button-color: $color-black;
$video-button-icon-color: $color-white;

.video__button {
  display: flex;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  height: $video-button-size;
  right: $global-spacing-sm;
  top: auto;
  bottom: $global-spacing-sm;
  z-index: 2;
  background-color: $video-button-color;
  transition: $video-button-transition-time $video-button-transition-ease,
  $video-button-transition-time $video-button-transition-ease, 0ms $video-button-transition-time;
  transition-property: opacity, visibility;

  @include hover-focus {
    .video__button-icon {
      color: darken($video-button-icon-color, 20%);
    }
  }

  @include keyboard-focus {
    opacity: 1;

    .video__button-icon {
      color: darken($video-button-icon-color, 20%);
    }
  }

  @include screen-tablet-portrait-and-smaller {
    height: $video-button-size-mobile;
  }
}

.video__button-icon-container {
  position: relative;
  width: $video-button-size;
  height: 100%;

  @include screen-tablet-portrait-and-smaller {
    width: $video-button-size-mobile;
  }
}

.video__button-icon {
  display: block;
  position: absolute;
  top: 50%;
  left: calc(50% + 1px);
  transform: translate(-50%, -50%);
  z-index: 2;
  font-size: 2rem;
  color: $video-button-icon-color;
  transition: $video-button-transition-time $video-button-transition-ease;
  transition-property: color, border;

  svg {
    width: $video-button-icon-width;
    height: $video-button-icon-height;
  }
}

.video__button-label {
  color: $color-white;
  max-width: 50vw;
  border-left: 1px solid var(--color-divider-line-dark);
  height: calc(100% - #{$global-spacing-xs * 2});
  padding: 0 $global-spacing-sm;

  span {
    display: block;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.45;
  }
}

.video__button--play {
  .video__button-icon {
    width: $video-button-icon-width;
    height: $video-button-icon-height;
    line-height: 0;
  }

  .video--is-paused & {
    opacity: 1;
    visibility: visible;
    @include video-button-show-props;
  }

  @include hover-focus {
    .video__button-icon {
      border-left-color: darken($video-button-icon-color, 20%);
    }
  }

  @include keyboard-focus {
    .video__button-icon {
      border-left-color: darken($video-button-icon-color, 100%);
    }
  }
}

.video__button--pause {
  .video__button-icon {
    letter-spacing: 3px;
  }

  .video--is-playing & {
    visibility: visible;
    @include video-button-show-props;
  }

  .video--is-playing:hover & {
    opacity: 1;
  }

  @include screen-tablet-portrait-and-smaller {
    .video--is-playing & {
        opacity: 1;
    }
  }
}

.video__button--replay {
  .video--is-ended & {
    opacity: 1;
    visibility: visible;
    @include video-button-show-props;
  }
}
