body {
  .fl-sidebar-wrp {
    @include screen-tablet-landscape-and-bigger {
      position: absolute;
      right: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  
    &__inner {
      @include screen-tablet-landscape-and-bigger {
        position: sticky;
        top: var(--sidebarTop);
      }
    }
  }
}

.fl-sidebar {
  margin-top: 2.4rem;

  @include screen-tablet-landscape-and-bigger {
    margin-top: 0;
  }

  &__breadcrumbs {
    display: flex;
    
    .product-detail__product-badges-wrapper {
      max-width: 90%;
      display: flex;
      flex-wrap: wrap;
      margin-left: auto;
      margin-top: -0.6rem;
      margin-bottom: -0.8rem;
    }

    .c-tag {
      margin-left: 1rem;
      margin-right: 0;
      margin-bottom: 1rem;

      .tag__label {
        font-size: 1.2rem;
        line-height: 2rem;
        letter-spacing: 0.01em;
        padding: 0.3rem 0.6rem 0.1rem;
      }
    }
  }

  .breadcrumb__button .button__label {
    font-weight: $font-weight-extrabold;
    letter-spacing: 0.01em;
  }

  .u-h3 {
    font-size: 2.4rem;
    line-height: 3.2rem;
    letter-spacing: -0.01em;
    margin: 0 0 0.8rem;

    @include screen-tablet-landscape-and-bigger {
      font-size: 3.2rem;
      line-height: 4rem;
    }
  }

  .product-detail__designers {
    .product-detail__designer-name {
      font-size: 1.6rem;
      line-height: 2.4rem;
    }
  }

  .seo-description__content {
    font-size: 1.6rem;
    line-height: 2.4rem;
    margin-bottom: 1.6rem;

    @include screen-tablet-landscape-and-bigger {
      font-size: 1.4rem;
      line-height: 2rem;
      letter-spacing: 0.01em;
      margin-bottom: 1.2rem;
    }
  }

  .fl-spareparts-link {
    margin-top: 1.6rem;

    @include screen-tablet-landscape-and-bigger {
      margin-top: 1.2rem;
    }

    &--mobile {
      display: block;

      @include screen-tablet-landscape-and-bigger {
        display: none;
      }
    }

    &--desktop {
      display: none;

      @include screen-tablet-landscape-and-bigger {
        display: block;
      }
    }

    .button__label {
      font-weight: $font-weight-extrabold;
      font-size: 1.4rem;
      line-height: 2rem;
    }
  }

  .fl-info-details {
    margin-top: 1rem;
    margin-bottom: 4rem;

    @include screen-tablet-landscape-and-bigger {
      margin-bottom: 2rem;
    }

    &__item {
      padding: 1.6rem 0;
      border-bottom: 1px solid $color-light-grey;

      &:last-child {
        border-bottom: none;
      }
    }

    &__text {
      display: block;
      font-size: 1.4rem;
      line-height: 2rem;
      margin: 0;
    }

    &__link {
      text-decoration: none;

      span {
        text-decoration: underline;
        margin-right: -4px;
      }
    }

    &__text-1 {
      font-size: 1.6rem;
      line-height: 2rem;
      margin: 0;

      &__link {
        text-decoration: none;
      }

      &__light {
        color: $color-tinted-grey;
      }
    }
  }

  .spare-part-list__header-variant-atributes-loop {
    border-top: 1px solid $color-light-grey;
    margin-bottom: 2.4rem;
  }

  .product-attribute__option {
    .c-product-attribute {
      margin: 0;
      border: none;
      border-bottom: 1px solid $color-light-grey;

      .product-attribute__label {
        padding: 1.6rem 0;
      }

      .product-attribute__select,
      .product-attribute__displayValue {
        padding: 1.6rem 0 1.6rem 0.8rem;
      }

      .product-attribute__select .select__label, .product-attribute__displayValue .select__label {
        padding: 0;
      }

      .select__input {
        padding-top: 0;
        padding-bottom: 0;
        color: $color-black;
      }

      .select__arrow svg {
        width: 1.2rem;
      }
    }
  }

  .fl-content-stock-delivery {
    display: flex;
    align-items: center;
    margin-bottom: 1.6rem;
  }

  .fl-stock-status {
    display: flex;
    align-items: center;

    &__bg {
      width: 1rem;
      height: 1rem;
      border-radius: 50%;

      &.fl-bg-green {
        background-color: #57C071;
      }
      &.fl-bg-red {
        background-color: #E14242;
      }
      &.fl-bg-yellow {
        background-color: #F8D45E;
      }
      &.fl-bg-grey {
        background-color: #E1D4DF;
      }
    }

    &__label {
      font-weight: $font-weight-extrabold;
      font-size: 1.6rem;
      line-height: 2rem;
      letter-spacing: 0.01em;
      margin: 0;
      padding: 0 0 0 0.8rem;

      @include screen-tablet-landscape-and-bigger {
        font-size: 1.4rem;
      }
    }
  }

  .fl-delivery-status {
    margin-left: 1.6rem;
    border-left: 1px solid $color-light-grey;
    padding-left: 1.6rem;

    .c-tag {
      margin: 0 !important;

      .tag__label {
        padding: 0;
        font-size: 1.6rem;
        line-height: 2rem;

        @include screen-tablet-landscape-and-bigger {
          font-size: 1.4rem;
        }
      }
    }
  }

  .fl-price {
    padding-top: 0.8rem;
    
    .price__item {
      font-weight: $font-weight-extrabold;
      font-size: 2rem;
      line-height: 2.4rem;
    }
  }

  .fl-color-attribute {
    display: block;

    .product-attribute__label {
      display: block;
      padding: 1.6rem 0 1.2rem;

      .fl-value {
        font-weight: $font-weight-regular;
      }
    }
  }

  .c-product-attribute .csb__list {
    top: 54px;
  }
}

.fl-pdp-spareparts-slider {
  .tns-ovh {
    height: auto !important;
  }
}

.fl-swatch {
  padding-bottom: 1.6rem;

  &__wrp {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -1rem;
  }

  &__btn-wrp {
    padding: 0 1rem;
    margin-bottom: 1rem;
  }

  &__btn {
    display: block;
    width: 2rem;
    height: 2rem;
    position: relative;

    &::before {
      content: '';
      transition: all 0.2s ease-in;
      display: block;
      border-radius: 50%;
      border: 1px solid #666;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: $color-white;
      z-index: 1;
    }
    
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
      border: 1px solid transparent;
      position: relative;
      z-index: 2;
    }

    @include screen-tablet-landscape-and-bigger {
      &:hover {
        &::before {
          border-color: #3D3D3D;
          transform: scale(1.25);
        }
      }
    }

    &--selected::before {
      border-color: #3D3D3D;
      transform: scale(1.25);
    }
  }
}

.fl-addtocart-section {
  display: flex;
  flex-direction: row;
  margin-top: 1.6rem;

  .c-button {
    height: 4.8rem;
  }

  .fl-content-select {
    width: 8rem;
    flex: 0 0 8rem;

    @include screen-tablet-landscape-and-bigger {
      width: 9.6rem;
      flex: 0 0 9.6rem;
    }

    .form__item {
      margin: 0;
    }

    .c-select--custom .select__label,
    .select__input {
      font-size: 1.6rem;
      line-height: 4.8rem;
      font-weight: $font-weight-extrabold;
      padding: 0 3.6rem 0 2rem;
      margin: 0;
      height: 4.8rem;
      border: 1px solid $color-grey;
    }

    .select__arrow {
      right: 1.4rem;

      svg {
        width: 1.4rem;
        color: $color-black;
      }
    }

    .csb__list {
      min-width: 8.1rem;
      margin-top: 2rem;
    }
  }

  .fl-content-add-to-cart {
    width: calc(100% - 8rem);
    flex: 0 0 calc(100% - 8rem);
    padding-left: 0.8rem;
    margin-top: 0;

    @include screen-tablet-landscape-and-bigger {
      width: calc(100% - 9.6rem);
      flex: 0 0 calc(100% - 9.6rem);
    }

    &.fl-content-on-order {
      min-width: 100%;
      padding: 0;
    }
  }
}

.fl-onorder-section {
  display: block;
  margin-top: 1.6rem;

  .c-button {
    height: 4.8rem;

    &--on-order {
      background-color: transparent;
      color: #000;
      border: 1px solid #000;
      &:hover {
        background-color: transparent !important;
        color: #000 !important;
      }

      .button__wrapper {
        gap: 10px;
      }

      .button__label {
        text-transform: none;
      }
    }
  }
}