//.c-icon-card {}

.icon-card__svg {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 42px;
  height: 42px;

  @include screen-tablet-landscape-and-bigger {
    width: 70px;
    height: 70px;
  }
}
