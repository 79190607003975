.c-hotspot-overlay {
  text-decoration: none;
  display: flex;
  gap: rem(20);

  @include hover-focus {
    .product-overlay__cta {
      transform: translateX(rem(5));
    }
  }
}

.hotspot-overlay__col {
  display: flex;

  &--image {
    @include aspect-ratio(1, "contain");
    justify-content: center;
    align-items: center;
    flex: 0 0 auto;
    max-width: rem(142);
    padding: rem(10);
    background-color: $color-light-grey;
  }

  &--content {
    flex-grow: 1;
    flex-direction: column;

    &,
    .c-price span {
      font-size: $font-size-set-text-small;
      line-height: 1.2;
      font-weight: $font-weight-regular;
      color: $color-dark-grey;
    }

    .c-price {
      display: inline-flex;

      span {
        transform: none;
        margin: 0;
      }
    }
  }
}

.hotspot-overlay__title {
  font-size: $font-size-body;
  color: $color-black;
  margin-right: rem(20); // to allow room for the modal close btn
}

.product-overlay__label {
  display: inline-block;
  margin-right: rem(5);
}

.product-overlay__cta {
  color: $color-black;
  transition: transform 200ms ease-in-out;
}