.c-spec-sheet {
  font-size: rem(9);
  margin: rem(40) rem(40) 0;

  .c-spec-sheet__heading,
  .c-spec-sheet__designers {
    font-size: rem(12);
  }

  .c-spec-sheet__container {
    display: flex;
  }

  .c-spec-sheet__texts {
    width: 62%;
  }

  .c-spec-sheet__images {
    max-width: 38%;
    margin-left: rem(20);

    img {
      max-width: rem(215);
    }
  }

  p {
    padding: 0;
    margin: 0;
  }

  dt {
    width: rem(145);
    padding-right: rem(5);

    p {
      width: rem(145);
    }
  }

  dd {
    text-align: justify;
  }

  .c-definition-table {
    color: initial;
  }

  .definition-table__col {
    width: 100%;
  }

  .definition-table__line {
    border-bottom: initial;
    justify-content: initial;
    padding: rem(4) 0;

    @include screen-tablet-landscape-and-bigger {
      padding: rem(4) 0;
    }
  }

  .c-spec-sheet__product-image {
    max-width: rem(402);
  }
}

.c-spec-sheet-download {
  display: none;
  margin-top: rem(8);
}

.c-definition-table--downloads {
  .definition-table__line {
    flex-direction: column;
  }
}
