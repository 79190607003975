.c-checkout-giftoption {
  background-color: $color-white;
  border: 2px solid $color-grey;

  .icon--chevron-down {
    display: none;
  }

  &.is-closed {
    .c-checkout-giftoption--content {
      display: none;
    }

    .icon--close {
      display: none;
    }
    .icon--chevron-down {
      display: inline-block;
    }
  }

  .c-checkout-giftoption--toggle {
    cursor: pointer;
    padding: rem(30);
  }

  .c-checkout-giftoption--content {
    padding: 0 rem(30) rem(30);
  }

  .icon--gift {
    fill: none;
  }

  .c-checkout-giftoption-title {
    flex: 1 0 auto;
    font-weight: $font-weight-bold;
  }

  .c-checkout-giftoption__subtitle {
    color: var(--color-form-item-placeholder);

    .content-asset {
      display: inline;
    }
  }

  .giftoption-viewall {
    @include text;

    vertical-align: middle;
    color: var(--color-form-item-placeholder);
  }

  .textarea__char-counter {
    color: var(--color-form-item-placeholder);
    font-size: rem(12);
    margin: rem(10) 0;
  }

  .input--textarea {
    margin-bottom: 0;
  }
}

.c-checkout-giftoption__checkbox {
  display: none;
}

.c-checkout-giftoption-popup {
  // Styling contentasset body
  h4 {
    margin-bottom: rem(30);
  }

  img {
    display: block;
    width: 100%;
    height: auto;

    border: 1px solid $color-grey;
  }
}
