body {
  @include screen-tablet-landscape-and-bigger {
    .fl-suggestions-col {
      flex: 1;
      width: 100%;
      max-width: 25%;
      padding-left: 3rem;
      margin-left: 0;

      &:first-child {
        padding-left: 0.8rem;
      }
    }
  }

  .fl-no-results {
    &__head {
      padding-bottom: 2.4rem;
      border-bottom: 0.1rem solid $color-light-grey;
    }

    &__title {
      display: block;
      font-weight: $font-weight-extrabold;
      font-size: 1.6rem;
      line-height: 2rem;
      color: $color-black;
      margin: 0 0 1.6rem;
      text-transform: uppercase;
    }

    &__text {
      display: block;
      font-weight: $font-weight-regular;
      font-size: 1.6rem;
      line-height: 2.4rem;
      color: $color-black-grey;
      margin: 0;

      a {
        font-weight: $font-weight-extrabold;
        text-decoration: underline;
        color: $color-black;
      }
    }

    &__body {
      padding: 1.6rem 0;
      border-bottom: 0.1rem solid $color-light-grey;

      @include screen-tablet-portrait-and-bigger {
        padding-bottom: 3rem;
      }

      &__title {
        display: block;
        font-weight: $font-weight-extrabold;
        font-size: 1.6rem;
        line-height: 2.4rem;
        color: $color-black;
        margin: 0 0 1.6rem;
      }

      &__products {
        margin: 0 -0.4rem;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        @include screen-tablet-portrait-and-bigger {
          margin: 0 -0.8rem;
          overflow-x: auto;
          flex-wrap: nowrap;
        }
      }
    }

    &__product {
      padding: 0 0.4rem 1.6rem;
      width: 50%;
      flex: 0 0 50%;

      @include screen-tablet-portrait-and-bigger {
        width: 21.4rem;
        flex: 0 0 21.4rem;
        padding-left: 0.8rem;
        padding-right: 0.8rem;
      }

      a {
        display: block;
        text-decoration: none;
        color: $color-black;

        &:hover .fl-image-showed-hover {
          @include screen-tablet-landscape-and-bigger {
            opacity: 1;
          }
        }
      }

      &__image {
        margin: 0 0 1.5rem;
        position: relative;

        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
          aspect-ratio: 3 / 4;
          
          &.fl-image-showed-hover {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: opacity 0.4s ease-in-out;
            opacity: 0;
          }
        }
      }

      &__title {
        display: block;
        font-weight: $font-weight-semi-medium;
        font-size: 1.6rem;
        line-height: 2rem;
        color: $color-black;
        margin: 0 0 0.4rem;
      }

      &__text {
        display: block;
        font-weight: $font-weight-regular;
        font-size: 1.4rem;
        line-height: 2rem;
        color: $color-black;
        letter-spacing: 0.01em;
        margin: 0;
      }
    }
  }
}