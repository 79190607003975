// Style
$button-spacing: 18px 20px 15px 20px;

// Transitions
$button-transition-time: 300ms;
$button-transition-ease: $ease-in-out-quad;

@mixin button {
  @include button--base;
  padding: $button-spacing;
  transition: $button-transition-time $button-transition-ease;
  transition-property: color, background-color, border-color, box-shadow;
  @include text-small;

  .button__label {
    display: block;
  }

  svg {
    fill: currentColor;
    transition: $button-transition-time $button-transition-ease;
    transition-property: fill;
  }
}
