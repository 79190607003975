$language-selector-toggle-icon-width: 12px;
$language-selector-toggle-icon-height: 6px;
$language-selector-list-min-width: 140px;
$language-selector-list-generic-spacing: 46px;

.c-language-selector {
  position: relative;
  display: flex;
}

.language-selector__toggle {
  @include text-small;
  padding: 0;

  svg {
    transition: transform 0.2s;
    width: $language-selector-toggle-icon-width;
    height: $language-selector-toggle-icon-height;
  }

  .button__icon {
    transform: none;
  }

  &.toggle--is-active {
    svg {
      transform: rotate(180deg);
    }
  }
}

.language-selector__list {
  display: none;
  position: absolute;
  right: 0;
  left: 0;
  top: $language-selector-list-generic-spacing;
  min-width: $language-selector-list-min-width;
  box-shadow: 0 0 4px 0 rgba($color-black, .15);
  background-color: var(--color-background-html);
  @if variable-exists('show-body-background-image') {
    @include background-grain;
  }

  @include screen-tablet-portrait-and-bigger {
    left: unset;
  }

  .c-footer & {
    box-shadow: none;
    border-bottom: 1px solid $color-dark-grey;
  }

  &.toggle--is-active {
    display: block;
  }
}

.language-selector__list--is-reversed {
  top: unset;
  bottom: $language-selector-list-generic-spacing;
}

.language-selector__list-item {
  width: 100%;
  padding: $global-spacing * 1.5 $global-spacing-sm;
  font-weight: $font-weight-regular;
  text-align: left;
  position: relative;

  .c-footer & {
    background-color: var(--color-background-footer-bottom);
    color: var(--color-text-footer-bottom);
  }

  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: currentColor;
    opacity: 0;
    transition: opacity 200ms;
  }

  &[disabled] {
    cursor: default;
    pointer-events: none;

    &::after {
      opacity: .1;

      .c-footer & {
        opacity: .2;
      }
    }

    .button__label {
      opacity: .5;
    }
  }

  &:hover:not([disabled]) {
    &::after {
      opacity: .075;

      .c-footer & {
        opacity: .15;
      }
    }
  }
}
