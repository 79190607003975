.basic-entry__image {
  overflow: hidden;

  @include screen-tablet-portrait-and-bigger {
    .c-slider--page-designer & {
      @include aspect-ratio(3/4);
    }
  }

  .image__default {
    transition: 400ms transform $ease-out-quad, opacity 200ms ease-out;
  }
}

.basic-entry--second-image {
  position: absolute;
  top: 24px;
  right: 0;
  left: 0;
  opacity: 0;
}