.c-footer {
  margin-top: $global-spacing;
  z-index: 2;

  &:not(.footer--minimal) {
    padding-top: 27px;

    @include screen-tablet-landscape-and-bigger {
      padding-top: 50px;
    }
  }

  .c-accordion {
    margin-bottom: 0;
  }
}

.footer__top {
  background-color: var(--color-background-footer);
  color: var(--color-text-footer-top);

  .o-grid {
    border-bottom: 1px solid $color-tinted-grey;

    @include screen-tablet-landscape-and-bigger {
      flex-wrap: initial;
      padding-bottom: 140px;
    }
  }
}

.footer__byline {
  @include text-small;
  font-weight: $font-weight-bold;
  text-transform: uppercase;
  margin-bottom: $global-spacing-xxs;

  @include screen-tablet-portrait-and-smaller {
    display: none;
  }
}

.footer__usps {
  margin-bottom: $global-spacing-md;

  .icon-list--horizontal {
    transform: initial;
  }

  .footer--minimal & {
    @include screen-tablet-portrait-and-smaller {
      width: 100%;
      padding-top: $global-spacing-sm;
      margin-top: 20px;
      border-top: 1px solid var(--color-divider-line);
    }

    @include screen-tablet-portrait-and-bigger {
      .icon-list--horizontal {
        transform: none;
      }
    }
  }
}

.footer__logo {
  width: 100px;
  height: 40px;
  position: absolute;
  right: 0;
  bottom: $global-spacing-md;
}

.footer__links {
  &:first-child {
    border-top: 0;
  }

  .accordion__item-header {
    font-weight: $font-weight-bold;
    text-transform: uppercase;
    padding-top: $global-spacing-sm;
    padding-bottom: $global-spacing-sm;

    @include screen-tablet-landscape-and-bigger {
      margin-bottom: $global-spacing-sm;
    }
  }
}

.footer__links-list {
  @include list-reset;
}

.footer__links-list-item {
  padding-left: 0;

  &:not(:last-child) {
    margin-bottom: $global-spacing-sm;
  }
}

.footer__links-list-item a,
button.footer__text-link {
  @include link--base-reversed;

  &:hover {
    color: $color-white !important;
  }
}

.footer__newsletter {
    margin-top: $global-spacing-md;

    @include screen-tablet-portrait-and-bigger {
      margin-top: $global-spacing-xxxxlg;
    }

    .o-grid {
      position: relative;
      align-items: start;
      flex-direction: column;

      @include screen-tablet-landscape-and-bigger {
        flex-direction: row;
        align-items: center;
      }
    }

    .newsletter-subscription-form__form {
      display: flex;
      flex-direction: column;

      @include screen-tablet-landscape-and-bigger {
        width: 70%;
        flex-direction: row;
        flex-wrap: wrap;
      }

      .form__item {
        margin-right: $global-spacing-sm;

        @include screen-tablet-landscape-and-bigger {
          width: 35%;
        }

        &.c-radio {
          width: 100%;

          .radio__wrapper {
            display: flex;
            flex-direction: column;

            @include screen-tablet-portrait-and-bigger {
                flex-direction: row;
            }
          }
        }

        &.c-checkbox {
          margin-bottom: 0;
          width: 100%;
        }
      }
    }

    .newsletter-form__content {
      .c-newsletter-form {
        margin: 0 0 $global-spacing-md  0 !important;
      }

      .c-alert {
        position: relative;
        width: initial;
        margin: 0 !important;

        @include screen-tablet-landscape-and-bigger {
          position: absolute;
          top: 0;
          right: 0;
          margin-top: $global-spacing-xxxxlg !important;
        }
      }
    }

    .newsletter-form__button {
      @include text-small;
      position: relative;
      text-transform: uppercase;
      margin: $global-spacing-xlg 0 0;
      width: 216px !important;

      @include screen-tablet-landscape-and-bigger {
        position: absolute;
        right: 0;
        top: 0;
        margin-top: $global-spacing-xxxxlg;
      }
    }
}

.footer__button-newsletter {
    @include text-small;
    margin: 0 0 $global-spacing-xlg;
    width: 216px;

    @include screen-tablet-landscape-and-bigger {
        margin: 0 0 0 auto;
    }
}

.footer__newsletter-text {
    margin: $global-spacing-xxlg 0 $global-spacing-lg;
    text-align: initial;

    @include screen-tablet-landscape-and-bigger {
      margin: $global-spacing * 9 0;
    }

    &.is--open {
      margin-bottom: $global-spacing-xlg !important;
    }
}

.footer__bottom {
  padding-top: $global-spacing-md;
  padding-bottom: $global-spacing-md;
  background-color: var(--color-background-footer-bottom);
  color: var(--color-text-footer-bottom);

  a,
  button {
    transition: opacity 250ms ease;

    @include hover-focus {
      opacity: .7;
    }
  }
}

.footer__language-selector {
  @include screen-tablet-landscape-and-bigger {
    order: 3;
  }

  .country-gateway__options-wrapper {
    display: flex;
    flex-direction: column;
    column-gap: $global-spacing-xlg;

    @include screen-tablet-portrait-and-bigger {
      flex-direction: row;
      align-items: center;
    }
  }
}


.footer__language-selector-select-item {
  display: inline-block;

  @include screen-tablet-portrait-and-bigger {
    &.form__item {
      margin-bottom: 0;
    }
  }

  .select__input-wrapper {
    display: inline-block;
  }

  .select__input {
    @include text-small;
    width: auto;
    padding-top: 0;
    padding-bottom: 0;
    border-bottom: 0;
    font-weight: $font-weight-bold;
  }

  .icon--chevron-down {
    width: 12px;
    height: 6px;
  }

  + .footer__language-selector-select-item {
    margin-left: 0;
  }
}

.footer__socials {
  @include screen-tablet-landscape-and-bigger {
    order: 1;
  }
}

.footer__socials-link {
  text-decoration: none;
  margin-right: $global-spacing-md;
}

.footer__support {
  @include screen-tablet-portrait-only {
    display: flex;
    align-items: center;
  }

  @include screen-laptop-and-bigger {
    display: flex;
    align-items: center;
  }
}

.footer__support-title {
  font-weight: $font-weight-bold;
  text-transform: uppercase;
  @include text-small;
  margin-bottom: $global-spacing-md;

  @include screen-tablet-portrait-only {
    margin-bottom: 0;
    margin-right: $global-spacing-md;
  }

  @include screen-tablet-landscape-and-bigger {
    display: block;
  }

  @include screen-laptop-and-bigger {
    margin-bottom: 0;
    margin-right: $global-spacing-md;
    display: inline-block;
  }
}

.footer__support-items {
  @include screen-tablet-portrait-and-bigger {
    display: inline-flex;
  }
}

.footer__support-link {
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  @include text-small;

  &:not(:last-child) {
    margin-right: $global-spacing-md;
  }

  @include hover-focus {
    color: var(--color-button-primary-background-hover);
  }
}

.footer__support-link-icon {
  margin-right: $global-spacing-xs;
}

.footer__partner {
  @include text-small;

  @include screen-tablet-landscape-and-bigger {
    order: 2;
    margin-top: 0;
  }
}

.footer__partner-content {
  @include screen-tablet-landscape-and-bigger {
    display: flex;
    height: 100%;
    align-items: center;
  }
}

.footer__uspsNewsletter {
  position: relative;
  width: 100%;
  margin-bottom: $global-spacing-sm;

  @include screen-tablet-landscape-and-bigger {
    margin: 0 0 $global-spacing-lg $global-spacing-xxxlg;
  }
}

.accordion__item-override-title,
.footer__links .accordion__item-header {
  @include h4;
  font-weight: $font-weight-medium;
  text-transform: initial;

  @include screen-tablet-landscape-and-bigger {
    font-size: 34px !important;
  }
}

.hs-button.primary {
  background-color: $color-white !important;
  color: $color-black !important;
}

html:not(.is-touch) .hs-form-field:hover .hs-input {
  color: $color-dark-grey;
}

.hs-input[type='email'] {
  color: $color-white !important;
  border-bottom: 1px solid $color-dark-grey !important;
}

.hs-form-booleancheckbox .hs-input + span {
  color: $color-dark-grey !important;
  width: 100%;

  &::before {
    filter: grayscale(1) invert(1);
  }

  &::after {
    filter: initial;
    border: 1px solid $color-white !important;
  }
}

.edit-profile-radio-wrapper {
  flex-direction: column;
  padding-left: 5px;
}
