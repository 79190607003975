.c-explore-link {
  display: flex;
  width: 100%;
  padding-bottom: 15px;
  text-decoration: none;

  @include hover-focus {
      .explore-link__label,
      .explore-link__arrow,
      .explore-link__image  {
        opacity: 1;
      }
  }

  @include screen-laptop-and-bigger {
      width: 55%;
  }
}

.explore-link__label {
  @include screen-tablet-landscape-and-bigger {
      opacity: .6;
      transition: opacity 200ms;
  }
}

.explore-link__image {
  display: none;
  pointer-events: none;

  @include screen-laptop-and-bigger {
      display: block;
      position: absolute;
      top: 20px;
      right: 0;
      width: 23%;
      height: 115%;
      opacity: 0;
      transition: opacity 250ms ease;
  }
}

.explore-link__arrow {
  margin-left: auto;

  @include screen-tablet-landscape-and-bigger {
      opacity: .6;
      transition: opacity 200ms;
  }
}
