.quote__text {
  @include h4;
  display: block;
  padding-bottom: $global-spacing-md;
  margin-bottom: $global-spacing-md;

  &::before,
  &::after {
    display: none;
  }

  @include screen-tablet-landscape-and-bigger {
    @include width-override(16, 'max-width');
  }
}

.quote__text--right {
  text-align: right;
  margin-left: auto;
}

.quote__text--center {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}
