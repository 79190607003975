.c-navigation-bar {
  background-color: var(--color-background-html);
  border-top: 1px solid var(--color-divider-line);
  z-index: 9;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: $navigation-bar-height;

  @include screen-tablet-landscape-and-bigger {
    border-top: 0;
  }
}

.navigation-bar--minimal {
  .experience-assets-navigationBarLink {
    margin-left: 0 !important;

    &:first-child {
      .navigation-bar__item {
        padding: 0 rem(20) 0 0 !important;

        @include screen-laptop-and-bigger {
          padding: 0 rem(17) 0 0 !important;
        }
      }
    }

    &:last-child {
      .navigation-bar__item {
        border-right: initial;
      }
    }
  }

  .navigation-bar__item {
    font-size: $font-size-set-text-large;
    line-height: rem(20);
    font-weight: 500;
    color: var(--color-black-transparent-60);
    border-right: rem(1.5) solid var(--color-black-transparent-60);
    padding-left: rem(20);

    @include screen-laptop-and-bigger {
      font-size: $font-size-set-text;
      line-height: rem(17);
      padding-left: rem(17);
    }

    &:focus {
      color: $color-black;
    }

    .navigation-bar__icon {
      display: none;
    }
  }

  .experience-assets-navigationBarLink:not(:last-child) {
    .navigation-bar__item {
      padding: 0 rem(20) 0;

      @include screen-laptop-and-bigger {
        padding: 0 rem(17) 0;
      }
    }
  }

  .u-background--scroll-gradient-left,
  .u-background--scroll-gradient-right {
    @include screen-laptop-and-bigger {
      display: none;
    }
  }

  .navigation-bar__title {
    font-weight: initial;
    margin-right: rem(77);
    padding: 0;
  }
}

.navigation-bar__list {
  position: relative;
  display: flex;
  align-items: center;

  .experience-navigationBarLinks {
    display: flex;
  }

  .experience-assets-navigationBarLink {
    display: flex;
    align-items: center;
    height: $navigation-bar-height;
    margin-left: $global-spacing-xxlg;

    &:first-child {
      margin-left: 0;
    }
  }

  @include screen-tablet-landscape-and-bigger {
    border-top: 1px solid var(--color-divider-line);
  }
}

.navigation-bar__title {
  display: none;
  margin-bottom: 0;
  padding-right: $global-spacing-md;
  font-weight: bold;
  white-space: nowrap;

  @include screen-tablet-landscape-and-bigger {
    position: relative;
    display: block;
    bottom: rem(2)
  }
}

.navigation-bar__item {
  @include text-large;
  text-decoration: none;
  white-space: nowrap;
  position: relative;
  display: block;
  padding-left: $global-spacing-xlg;
}

.navigation-bar__icon {
  position: absolute;
  top: rem(-3);
  left: 0;
  width: rem(19);
  height: rem(19);

  &:not(&--is-active) {
    top: rem(3);
    border-radius: 50%;
    border: rem(1) solid var(--color-text);
  }
}
