$mega-menu-item-padding: 16px;

.mega-menu__nav {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  list-style: none;
  z-index: 2;
  position: relative;
  margin-left: -$mega-menu-item-padding;

  ul {
    display: flex;
    list-style: none;
  }
}

.mega-menu__group {
  height: 100%;
}

.mega-menu__background {
  position: absolute;
  opacity: 0;
  top: 100%;
  left: 50%;
  width: 100vw;
  height: 0;
  transform-origin: left top;
  transform: translate3d(-50%, 0, 0);
  transition: 200ms ease-in-out;
  transition-property: opacity;
  z-index: 1;
  pointer-events: none;
  background-color: var(--color-background-mega-menu);
  @if variable-exists('show-body-background-image') {
    @include background-grain;
  }

  .header--mega-menu-open & {
    opacity: 1;
    pointer-events: all;
    height: calc(100vh - var(--header-height));
  }
}

.mega-menu__full-page-background {
  top: var(--header-height);
  height: 100vh;
  transition: 400ms ease-out 800ms, 0ms;

  .header--mega-menu-open & {
    left: 0;
    opacity: 1;
    transition: 200ms ease-in-out, 200ms ease-in-out, 0ms;
    transition-property: opacity, transform;
  }
}

.mega-menu__flyout {
  position: fixed;
  top: var(--header-height);
  display: block;
  left: 0;
  transform: translate3d(0, -40px, 0);
  width: 100vw;
  height: calc(100vh - var(--header-height));
  z-index: 10;
  opacity: 0;
  pointer-events: none;
  transition: opacity 150ms linear, transform 150ms $ease-out-quad;

  .mega-menu__item--is-open & {
    transform: translate3d(0, 0, 0);
    opacity: 1;
    pointer-events: all;
    transition: opacity 250ms linear, transform 350ms $ease-out-quad;
  }
}
