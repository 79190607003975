.mega-menu__link {
  @include text-small;
  text-decoration: none;
  padding: $global-spacing $mega-menu-item-padding;
  display: flex;
  align-items: center;
  position: relative;
  white-space: nowrap;
  height: 100%;

  &[aria-expanded="true"] {
    .button__label {
        border-color: currentColor;
    }
  }

  @include keyboard-focus {
    position: relative;
    @include focus-state-pulse;
  }

  @include hover-focus {
    .button__label {
      border-color: currentColor;
    }
  }

  @include screen-tablet-landscape-and-bigger {
    height: $header-height-bottom-desktop;
  }

  .button__label {
    display: block;
    transition: border-color 300ms $ease-out-quad;
    border-bottom: 1px solid transparent;
  }
}
