// On a white (white-grey) background these colors are allowed for text:
// black, black-grey, dark-grey

:root, .t-default {
  // Core color settings
  --color-primary: #{$color-black};
  --color-background-html: #{$color-white};
  --color-background-html-0: #{rgba($color-white, 0)};
  --color-background-html-50: #{rgba($color-white, .5)};
  --color-background: #{$color-white-grey};
  --color-background-column: #{$color-white};
  --color-background-mega-menu: #{$color-white};
  --color-background-inverted-10: #{rgba($color-black, .1)};
  --color-background-footer: transparent;
  --color-background-footer-bottom: #{$color-black};
  --color-image-background: #{$color-white};
  --color-image-background-70: #{rgba($color-white, .7)};
  --color-text: #{$color-black};
  --color-text-dark: #{$color-black};
  --color-text-light: #{$color-dark-grey};
  --color-text-lightest: #{$color-tinted-grey};
  --color-text-inverted: #{$color-white};
  --color-text-inverted-70: #{rgba($color-white, .7)};
  --color-text-footer: var(--color-text);
  --color-text-footer-top: #{$color-white};
  --color-text-footer-bottom: var(--color-text-inverted);
  --color-definition-table: #{$color-dark-grey};
  --color-definition-table-title: #{$color-black-grey};
  --color-divider-line: #{$color-black-transparent-15};
  --color-divider-line-light: #{$color-black-transparent-8};
  --color-divider-line-dark: #{$color-black-grey};
  --color-slider-indicator-line: #{$color-black};
  --color-slider-status-line: #{$color-black-transparent-15};
  --color-light: 244, 239, 231;
  --color-tag: #{$color-moss-green-light};
  --color-tag-text: var(--color-text);
  --color-black-transparent-25: #{$color-black-transparent-25};
  --color-black-transparent-60: #{$color-black-transparent-60};

  // Cursors
  --cursor-zoom-in: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 50 50' opacity='.7' width='40' height='40'%3E%3Crect fill='%23000000' width='50' height='50'/%3E%3Cg%3E%3Cpath fill='%23ffffff' d='M0,0v50h50V0H0z M49,49H1V1h48V49z'/%3E%3Cpolygon fill='%23ffffff' points='24.5,33.4 25.5,33.4 25.5,25.5 33.4,25.5 33.4,24.5 25.5,24.5 25.5,16.6 24.5,16.6 24.5,24.5 16.6,24.5 16.6,25.5 24.5,25.5 '/%3E%3C/g%3E%3C/svg%3E%0A") 25 25, zoom-in;
  --cursor-zoom-out: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 50 50' opacity='.7' width='40' height='40'%3E%3Crect fill='%23000000' width='50' height='50'/%3E%3Cg%3E%3Cpath fill='%23ffffff' d='M0,0v50h50V0H0z M49,49H1V1h48V49z'/%3E%3Crect fill='%23ffffff' x='16.6' y='24.5' width='16.8' height='1'/%3E%3C/g%3E%3C/svg%3E%0A") 25 25, zoom-out;

  // Specific spacing
  --main-spacing-top: #{$global-spacing-xlg};
  --main-spacing-top-mobile: #{$global-spacing-xlg};
  --main-spacing-top-negative: #{$global-spacing-xlg * -1};
  --main-spacing-top-mobile-negative: #{$global-spacing-xlg * -1};
  --default-block-space-mobile: #{$global-spacing * 10};
  --default-block-space-desktop: #{$global-spacing * 20};
  --default-block-space-small: #{$global-spacing-lg};
  --default-pd-asset-space: #{$global-spacing-md};
  --hero-image-height-mobile: 325px;
  --hero-image-height-desktop: 675px;

  // Menu settings
  --hamburger-size: 36px;
  --hamburger-thickness: 1px;
  --hamburger-spacing: 5px;
  --hamburger-border-radius: 0;

  // Core background settings see stylesheet.isml

  // Button colors
  --color-product-button: #{$color-black};

  // - Primary
  --color-button-primary-background: #{$color-black};
  --color-button-primary-text: #{$color-white};
  --color-button-primary-background-hover: #{$color-black-transparent-75};
  --color-button-primary-background-focus: #{$color-dark-grey};
  --color-button-primary-background-disabled: #{$color-light-grey};
  --color-button-primary-font-disabled: #{$color-dark-grey};

  // - Ghost
  --color-button-ghost: #{$color-black};
  --color-button-ghost-underline: #{$color-black-transparent-40};
  --color-button-ghost-hover: #{$color-tinted-grey};
  --color-button-ghost-hover-text: #{$color-black-grey};
  --color-button-ghost-hover-icon-only: #{$color-black-grey};
  --color-button-ghost-focus: #{$color-tinted-grey};
  --color-button-ghost-focus-text: #{$color-black-grey};
  --color-button-ghost-focus-background: #{$color-light-grey};
  --color-button-ghost-disabled: #{$color-grey};
  --color-button-ghost-disabled-text: #{$color-dark-grey};

  // Form Colors
  --color-form-item-placeholder: #{$color-dark-grey};
  --color-form-item-default: #{$color-dark-grey};
  --color-form-item-hover: #{$color-black};
  --color-form-item-hover-text: #{$color-black};
  --color-form-item-focus: #{$color-black};
  --color-form-item-focus-text: #{$color-black};
  --color-form-item-disabled: #{$color-dark-grey};
  --color-form-item-disabled-text: #{$color-dark-grey};
  --form-checkbox-icon-white: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMSwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxMCA2IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxMCA2OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6I0ZGRkZGRjt9Cjwvc3R5bGU+Cjxwb2x5Z29uIGNsYXNzPSJzdDAiIHBvaW50cz0iOS4zLDAgMy42LDQuOCAwLjcsMi40IDAsMyAzLjYsNiAxMCwwLjcgIi8+Cjwvc3ZnPgo=');
  --form-checkbox-icon: url('data:image/svg+xml;base64,CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgMTAgNiI+Cjxwb2x5Z29uIGZpbGw9ImN1cnJlbnRDb2xvciIgcG9pbnRzPSI5LjMsMCAzLjYsNC44IDAuNywyLjQgMCwzIDMuNiw2IDEwLDAuNyAiLz4KPC9zdmc+Cg==');
  --shipping-method-border: #{$color-grey};
  --shipping-method-border-focus: #{$color-black};
  --shipping-method-border-hover: #{$color-dark-grey};
  --shipping-method-border-active: #{$color-black};

  // States colors
  --color-success: #{$color-moss-green-light};
  --color-alert-success: var(--color-success);
  --color-success-text: #{$color-moss-green};
  --color-success-alert-text: var(--color-text);
  --color-error: #{$color-warm-red-light};
  --color-alert-error: var(--color-error);
  --color-error-20: #{rgba($color-warm-red-light, .2)};
  --color-error-text: #{$color-warm-red};
  --color-error-alert-text: var(--color-text);
  --color-info: #{$color-blue-sky-light};
  --color-alert-info: var(--color-info);
  --color-info-text: #{$color-blue-sky};
  --color-info-alert-text: var(--color-text);

  // Invert
  --arco-invert: invert(0);
}
