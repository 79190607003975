.discover-more__block-container {
  background-color: $color-light-grey;
  margin-top: 84px;
}

.discover-more__block-content-container {
  width: 100%;
  display: flex;
  flex-flow: column;
  margin: 84px 0 70px 0;

  @include screen-mobile-plus-and-smaller {
    margin: 32px 0 48px 0;
  }
}

.discover-more__block {
  display: flex;
  justify-content: space-between;
  margin-top: $global-spacing-lg;

  &-content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include screen-mobile-plus-and-smaller {
      flex-flow: column;
    }
  }

  &-text {
    display: flex;
    flex-flow: column;
    margin-bottom: $global-spacing-lg;
    text-align: initial;

    @include screen-mobile-plus-and-smaller {
      margin-bottom: $global-spacing-md;
    }
  }

  &-button {
    @include text-small;
    width: 216px;
  }

  &-find-store,
  &-professional-area {
    width: 49%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px;
    background-color: $color-off-white;

    @include screen-mobile-plus-and-smaller {
      width: 100%;
      flex-flow: column-reverse;
      padding: 26px;
      align-items: flex-start !important;
    }

    @include screen-mobile-plus-and-smaller {
      .find-store-icon {
        width: 30px;
      }

      .professional-area-arrow-icon {
        width: 20px;
      }
    }

    &-content {
      width: 70%;
      height: 100%;
      display: flex;
      flex-flow: column;
      justify-content: space-between;

      @include screen-mobile-plus-and-smaller {
        width: 100%;
      }
    }
  }

  @include screen-mobile-plus-and-smaller {
    &-find-store {
      margin-bottom: $global-spacing-lg;
    }
  }
}
