.c-slider {
  .tns-ovh {
    @include screen-tablet-portrait-and-smaller {
      padding-left: $global-spacing-md;
    }
  }

  &--tile {
    position: relative;
    overflow-x: hidden;

    .tns-ovh {
      @include screen-tablet-portrait-and-smaller {
        padding-left: 0;
      }
    }

    .slider__controls {
      position: absolute;
      right: 10px;
      bottom: 5px;
      z-index: 1;

      button {
        position: absolute;
        right: 0;
        bottom: 0;
      }
    }

    .slider__button[disabled] {
      visibility: hidden;
      z-index: -1;
    }
  }
}

.is-touch {
  .c-slider {
    &--tile {
      .slider__button {
        @include screen-tablet-portrait-and-smaller {
          display: block;
        }
      }
    }
  }
}

.slider--hidden {
  overflow: hidden;
  height: 0;
  opacity: 0;
}

.slider__header {
  margin-bottom: $global-spacing-sm;

  @include screen-tablet-landscape-and-bigger {
    display: flex;
    align-items: flex-end;
  }
}

.slider__title {
  @include h2-secondary;

  .c-slider--page-designer & {
    @include h3-secondary;
  }

  @include screen-tablet-portrait-and-smaller {
    margin-bottom: $global-spacing-md;
  }
}

.slider__subtitle {
  @include h6;
  display: block;
  margin-bottom: $global-spacing-xs;

  @include screen-tablet-landscape-and-bigger {
    margin-bottom: $global-spacing-sm;
  }
}

.slider__controls {
  display: flex;
  align-items: center;
  margin-left: 0;

  @include screen-tablet-landscape-and-bigger {
    margin-left: auto;
  }
}

.slider__button {
  transition: opacity 300ms linear;

  .button__icon {
    transform: translate3d(0, -2px, 0);
  }

  &[disabled] {
    opacity: .4;
  }

  .is-touch & {
    @include screen-tablet-portrait-and-smaller {
      display: none;
    }
  }
}

.slider__button--prev {
  margin-left: -10px;
}

.slider__status-bar {
  margin-top: $global-spacing-md;
  width: 100%;
  height: 1px;
  background-color: var(--color-slider-status-line);
  position: relative;

  .c-slider--page-designer & {
    margin-top: $global-spacing-xlg;

    @include screen-tablet-landscape-and-bigger {
      margin-top: $global-spacing-xxxlg;
    }
  }
}

.slider__status-indicator {
  width: 0;
  height: 1px;
  position: absolute;
  top: 0;
  background-color: var(--color-slider-indicator-line);
  transition: 300ms transform;
  transform: translateX(0);
}

.slider__meta {
  margin-bottom: -1px;
}
