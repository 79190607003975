body {
  &[data-action="Product-Show"] {
    main {
      padding-top: 0;

      @include screen-tablet-landscape-and-bigger {
        padding-top: var(--main-spacing-top);
      }
    }

    .spare-part-list__header-variant-wrapper {
      .fl-color-attribute {
        flex-wrap: wrap;

        .fl-swatch {
          flex: 0 0 100%;

          @include screen-tablet-landscape-and-bigger {
            padding: 5px 0 0;
          }
        }
      }
      .c-product-attribute:not(.product-attribute--radio) {
        margin: 0;
        border: none;
        border-bottom: 1px solid var(--color-divider-line);

        @include screen-tablet-landscape-and-bigger {
          flex-wrap: wrap;
        }

        .product-attribute__label {
          padding-left: 0;
        }

        .product-attribute__select, .product-attribute__displayValue {
          padding-right: 0;

          @include screen-tablet-landscape-and-bigger {
            flex: 0 0 100%;
            padding: 0;
          }
        }

        .product-attribute__displayValue {
          @include screen-tablet-landscape-and-bigger {
            padding-bottom: 11px;
          }

          + .input--hidden {
            display: none;
          }
        }

        .select__arrow {
          width: 1.2rem;
        }
      }
    }
  }

  .fl-pdp-sticky {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 10;
  }

  .fl-pdp-spareparts {
    position: relative;
    padding-top: 1.6rem;
    border-top: 1px solid var(--color-divider-line);

    .spare-parts__pdp-button-popular {
      display: block;
      margin-right: auto;
      font-weight: $font-weight-extrabold;
      font-size: 2rem;
      line-height: 2.4rem;
      letter-spacing: 0.01em;
      text-transform: uppercase;
    }

    .definition-table__header-link {
      margin-bottom: 0;
      color: $color-black;

      .button__label {
        margin-right: 0.8rem;
      }

      svg {
        width: 2.4rem;
        height: 2.4rem;
      }
    }
  }

  .fl-pdp-spareparts-slider {
    .slider__container {
      display: flex;
    }
    
    .c-product-card {
      margin: 0;
    }

    .product-card__body {
      .u-h5 {
        font-weight: $font-weight-semi-medium;
        font-size: 1.6rem;
        line-height: 2rem;
        margin-bottom: 0.8rem;
      }

      .price {
        font-weight: $font-weight-extrabold;
        font-size: 1.6rem;
        line-height: 2rem;
      }
    }
  }

  .fl-content-related {
    #pdp-linked-products,
    #pdp-recommendations,
    #product-recommendations-m {
      padding: 4rem 0;
      margin-top: 6.4rem;
  
      @include screen-tablet-landscape-and-bigger {
        padding: 5.6rem 0;
        margin-top: 7.2rem;
      }

      div[id^='cq_recomm_slot-'] {
        background: $color-white;

        .o-container {
          padding-top: 24px;
        }
      }
    }

    .product-detail__recommendations {
      margin-top: 0 !important;
    }

    .slider__header {
      display: flex;
      align-items: center;
      margin: 0 0 3.2rem;
    }

    .slider__title {
      display: block;
      font-size: 2rem;
      line-height: 2.4rem;
      letter-spacing: 0.01em;
      color: $color-black;
      font-weight: $font-weight-extrabold;
      text-transform: uppercase;
      margin: 0;

      p {
        margin: 0;
      }
    }

    .slider__controls {
      margin-left: auto;
    }

    .tns-nav {
      display: none;
    }
  }

  .fl-content-gallery {
    @include screen-tablet-landscape-and-bigger {
      margin-bottom: 5.6rem;
    }
  }

  .fl-content-banner {
    margin-bottom: 2.4rem;

    @include screen-tablet-landscape-and-bigger {
      margin-bottom: 4rem;
    }
  }

  .fl-content-meet-designer {
    margin: 0 -24px;

    @include screen-tablet-portrait-and-bigger {
      margin: 0;
    }
  }

  .fl-short-description {
    margin: 5.6rem 0 0.8rem;

    &__text {
      display: block;
      font-size: 2rem;
      line-height: 2.8rem;
      margin: 0;

      @include screen-tablet-portrait-and-bigger {
        font-size: 2.4rem;
        line-height: 3.2rem;
      }
    }
  }

  .fl-clamp {
    margin-bottom: 1.6rem;

    &:not(.toggle--is-active) .fl-short-description__text {
      display: -webkit-box;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }

  .fl-pdp-row-container {
    position: relative;
  }
}