@mixin h1 {
  @include fluid-type(map_get($font-size-set-h1, 'xxlg'), map_get($font-size-set-h1, 'xs'));
  @include fluid-line-height($line-height-set-h1);
  font-family: $font-stack-primary;
  font-weight: $font-weight-bold;
  //letter-spacing: -1.5px;
  text-transform: uppercase;
  word-break: break-word;
  transform: translateX(-4px);

  @include screen-tablet-portrait-and-bigger {
    transform: translateX(-7px);
  }
  @include screen-tablet-landscape-and-bigger {
    transform: translateX(-10px);
  }
}

@mixin h1-large {
// not used for Flos
}

@mixin h1-secondary {
  @include fluid-type(map_get($font-size-set-h1-secondary, 'xxlg'), map_get($font-size-set-h1-secondary, 'xs'));
  @include fluid-line-height($line-height-set-h1-secondary);
  font-weight: $font-weight-regular;
  font-family: $font-stack-secondary;
  text-transform: none;
  word-break: break-word;

  strong {
    font-weight: $font-weight-regular;
  }

  @include screen-tablet-landscape-and-bigger {
    letter-spacing: -0.3rem;
  }
}

@mixin h2 {
  @include fluid-type(map_get($font-size-set-h2, 'xxlg'), map_get($font-size-set-h2, 'xs'));
  @include fluid-line-height($line-height-set-h2);
  font-family: $font-stack-primary;
  font-weight: $font-weight-semi-medium;
  //letter-spacing: -1px;
  text-transform: uppercase;
  word-break: break-word;
  transform: translateX(-3px);

  @include screen-tablet-landscape-and-bigger {
    transform: translateX(-4px);
  }
}

@mixin h2-secondary {
  @include fluid-type(map_get($font-size-set-h2-secondary, 'xxlg'), map_get($font-size-set-h2-secondary, 'xs'));
  @include fluid-line-height($line-height-set-h2-secondary);
  font-family: $font-stack-secondary;
  font-weight: $font-weight-semi-medium;
  text-transform: none;
  word-break: break-word;

  strong {
    font-weight: $font-weight-semi-medium;
  }
}

@mixin h3 {
  @include fluid-type(map_get($font-size-set-h3, 'xxlg'), map_get($font-size-set-h3, 'xs'));
  @include fluid-line-height($line-height-set-h3);
  font-family: $font-stack-primary;
  font-weight: $font-weight-semi-medium;
  text-transform: uppercase;
  word-break: break-word;
  transform: none;
}

@mixin h3-secondary {
  @include fluid-type(map_get($font-size-set-h3-secondary, 'xxlg'), map_get($font-size-set-h3-secondary, 'xs'));
  @include fluid-line-height($line-height-set-h3-secondary);
  font-family: $font-stack-secondary;
  font-weight: $font-weight-semi-medium;
  text-transform: none;
  word-break: break-word;

  strong {
    font-weight: $font-weight-semi-medium;
  }
}

@mixin h4 {
  @include fluid-type(map_get($font-size-set-h4, 'xxlg'), map_get($font-size-set-h4, 'xs'));
  @include fluid-line-height($line-height-set-h4);
  font-family: $font-stack-primary;
  font-weight: $font-weight-medium;
  text-transform: none;
  word-break: break-word;
  transform: none;
}

@mixin h5 {
  @include fluid-type(map_get($font-size-set-h5, 'xxlg'), map_get($font-size-set-h5, 'xs'));
  @include fluid-line-height($line-height-set-h5);
  font-family: $font-stack-primary;
  font-weight: $font-weight-bold;
  text-transform: uppercase;
  word-break: break-word;
  transform: none;
}

@mixin h5-large {
  @include fluid-type(map_get($font-size-set-h5-large, 'xxlg'), map_get($font-size-set-h5-large, 'xs'));
  @include fluid-line-height($line-height-set-h5);
  font-family: $font-stack-primary;
  font-weight: $font-weight-bold;
  text-transform: uppercase;
  word-break: break-word;
  transform: none;
}

@mixin h6 {
  @include fluid-type(map_get($font-size-set-h6, 'xxlg'), map_get($font-size-set-h6, 'xs'));
  font-family: $font-stack-primary;
  line-height: $line-height-h6;
  font-weight: $font-weight-bold;
  text-transform: uppercase;
  word-break: break-word;
  transform: none;
}

@mixin text {
  font-size: $font-size-set-text;
  line-height: $line-height-set-text;
}

@mixin text-small($line-height: true) {
  font-size: $font-size-set-text-small;

  @if ($line-height != false) {
    line-height: $line-height-set-text-small;
  }
}

@mixin text-xsmall($line-height: true) {
  font-size: $font-size-set-text-xsmall;

  @if ($line-height != false) {
    line-height: $line-height-set-text-xsmall;
  }
}

@mixin text-large($line-height: true) {
  font-size: $font-size-set-text-large;

  @if ($line-height != false) {
    line-height: $line-height-set-text-large;
  }
}

@mixin p-large($line-height: true) {
  @include fluid-type(map_get($font-size-set-p-large, 'xxlg'), map_get($font-size-set-p-large, 'xs'));
  @include fluid-line-height($line-height-p-large);
}

@mixin multi-line-text--underline($color, $size: 1px, $paddingBottom: $global-spacing-xxs) {
  display: inline;
  background-image: linear-gradient($color, $color);
  background-position: 0 100%;
  background-repeat: no-repeat;
  background-size: 100% $size;
  padding-bottom: $paddingBottom;
  transition: 300ms ease background-image;
}

@function rem($value) {
  @return #{strip-unit($value) / strip-unit($font-size-root-px)}rem;
}
