.paged-navigation__navigation-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: $global-spacing-md 0;
  border-bottom: 1px solid var(--color-divider-line);
  font-weight: $font-weight-medium;
  text-decoration: none;

  @include screen-tablet-landscape-and-bigger {
    padding: 28px 0;
  }

  .c-tag {
    margin-bottom: 0;
  }
}
