.c-collapsible__text-block {
    display: none;

    &.c-collapsible__text--active {
        display: inline;
    }
}

.c-collapsible__text-toggle {
    cursor: pointer;
}
