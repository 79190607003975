$border-black: 1px solid $color-black;
$border-grey: 1px solid $color-dark-grey;

.c-store-locator {
  height: 100%;
  display: flex;
  flex-direction: column;

  .input__cta {
    padding: 0;
    right: unset;
    color: $color-black;

    svg {
      width: 34px;
      height: 34px;
    }
  }

  img[alt="Locator"] {
    margin-top: $global-spacing - 6;
  }

  .c-loader {
    position: fixed;
  }

  .search-results__filter-button {
    margin-left: 0;

    button {
      font-size: rem(16);
      font-weight: 400;
    }

    svg {
      width: rem(16);
      height: rem(16);
    }
  }

  .c-paged-navigation.refinement-menu--landing {
    padding: rem(26);
    justify-content: space-between;

    .refinement-menu__category-title {
      font-weight: 700;
    }

    @include screen-tablet-landscape-and-bigger {
      padding: rem(153) rem(40) rem(34) rem(40);
      margin-top: 0;
    }
  }

  .refinement-menu-title {
    @include text;

    font-size: $font-size-root * 2.4;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: rem(40);
    margin-top: rem(120);

    @include screen-tablet-landscape-and-bigger {
      margin-top: rem(35);
    }
  }

  .refinement-menu__actions {
    margin-top: rem(80);

    &-close {
      width: 100%;
      text-align: center;
      padding: $global-spacing*2 0;
      font-weight: 700;
      background-color: $color-white;
      color: $color-black;
      border: $border-black;

      @include hover-focus {
        background-color: $color-white !important;
      }
    }
  }

  .c-refinement-options--section {
    margin: rem(34) 0 0;
    text-transform: capitalize;
  }
}

.store-locator__sidebar-wrapper {
  padding-left: 0 !important;
  padding-right: 0 !important;

  @include screen-tablet-landscape-and-bigger {
    margin-top: $global-spacing * 3;
    margin-right: $global-spacing * 3.375;
  }
}
.store-locator__header {
  margin-bottom: $global-spacing * 2.375;

  .store-locator__header-title{
    font-size: 3.2rem;
    font-weight: 700;
    margin-top: -$global-spacing + 3;
    margin-left: $global-spacing - 4;
  }

  @include screen-tablet-landscape-and-bigger {
    margin-bottom: 0;
  }
}

.store-locator__cookie-message {
  padding: rem(16);
  margin-bottom: 0;
}

.store-locator__result {
  display: flex;
  flex-direction: column;
  width: 100%;

  .paged-navigation-modal__content {
    margin: 0 auto 0 0;
  }
}

.store-locator__result-title {
  display: block;
  font-size: 14px;
}


.store-locator__result-nearest {
  display: none;

  @include screen-tablet-landscape-and-bigger {
    display: block;
    margin-bottom: $global-spacing * 4;
    line-height: $line-height-set-text;
    color: gray;
  }
}

.store-locator__location-not-found {
  display: block;
}

.store-locator__result-title--hidden,
.store-locator__result-nearest--hidden,
.store-locator__location-not-found--hidden {
  display: none !important;
}

.store-locator__title {
  @include text;
}

.store-locator__content {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.store-locator__sidebar {
  @include screen-tablet-landscape-and-bigger {
    display: flex;
    flex-direction: row;
  }
}

.store-locator__sidebar--map-is-active {
  visibility: visible !important;
}

.store-locator__form {
  @include screen-tablet-landscape-and-bigger {
    width: 50%;
    position: relative;
    z-index: 2;
  }
}

.store-locator__filter-results {
  align-items: center;
  width: 100%;
  padding-top: rem(13);
  padding-bottom: rem(10);
  border-bottom: $border-grey;
}

.store-locator__form-input {
  display: flex;
  align-items: flex-start;
  margin: rem(30) 0 0;
  border-bottom: $border-black;

  img {
    cursor: pointer;
  }

  .form__item {
    width: 100%;
    margin: 0;
  }

  .input__input {
    line-height: $line-height-set-text;
    background-color: transparent !important;
    padding-left: rem(35);
    padding-bottom: rem(17);
    border-bottom: none;
    padding-top: 0;
  }

  .icon--search-arclinea {
    margin-top: 0;
  }

  .c-icon.icon--arrow-storelocator-search-arclinea {
    margin-top: rem(3);
  }
}

.store-locator__form-input--address {
  flex: 1 0 65%;

  svg {
    fill: $color-white;
    margin-top: $global-spacing - 4;
  }

  @include screen-tablet-portrait-and-bigger {
    flex: 1;
  }
}

.store-locator__amount-found {
  color: $color-black;
  margin-bottom: $global-spacing * 4;

  .c-modal & {
    display: none;
  }
}

.store-locator__results-row {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  position: relative;

  @include screen-tablet-landscape-and-bigger {
    flex-direction: row;
  }
}

.store-locator__results {
  overflow: auto;
  height: 100%;
  background-color: $color-white;
}

.store-locator__tabs {
  .tabs__nav {
    padding: 0;

    &::before {
      content: initial;
    }
  }

  .tabs__nav-item {
    margin: 0 !important;
  }

  .tabs__nav-button {
    cursor: pointer;
    color: $color-black;
    border: 0;

    &:not(.tabs__nav-button--is-active) {
      color: $color-dark-grey;
      border-bottom: $border-grey;
      background: transparent !important
    }
  }

  .tabs__nav-button--is-active {
    border-bottom: $border-black;
    background: transparent !important;
    box-shadow: initial;
  }
}

.store-locator__tabpanel {
  visibility: hidden;
  position: relative;
  width: 100%;
  height: 100%;

  &.is--active,
  &.is--active-desktop {
    visibility: visible;
  }

  &.is--active-desktop {
    @include screen-tablet-landscape-and-bigger {
      margin: 0;
      width: 100%;
      position: static;
    }
  }
}

.store-locator__no-results,
.store-locator__location-not-found {
  @include h6;
  padding: $global-spacing * 2;
  margin-top: $global-spacing * 2;
  text-transform: none;
  font-weight: $font-weight-regular;
  background: $color-blue-sky-light;
}

.store-locator__store {
  border-bottom: $border-grey;
}

.store-locator__map-wrapper {
  display: none;
  height: rem(901);

  @include screen-tablet-landscape-and-bigger {
    display: flex;
    position: sticky !important;
    top: 5%;
    flex: 1 0 63%;
  }
}

.store-locator__selected-modal {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  transform: translateY(0);
  background-color: $color-white;
  z-index: 10;
  padding: rem(17) rem(24) rem(0);
  box-shadow: rem(0) rem(-3) rem(10) $color-black-transparent-25;
  border-radius: rem(5);
  transition: transform 0.5s;

  @include screen-tablet-landscape-and-bigger {
    display: none;
  }

  .store-locator__selected-modal-line {
    width: rem(50);
    height: rem(3);
    background-color: $color-grey;
    border-radius: rem(3);
    margin-bottom: rem(16);
  }

  .c-icon.icon--arrow-right-arclinea {
    margin-left: rem(5);
    width: rem(20);
    height: rem(16);
  }
}

.store-locator__selected-content--active {
  margin-bottom: (2.8rem);
}

.store-locator__map-wrapper.store-locator__tabpanel.is--active {
  display: flex;
}

.store-locator__map-selected-store {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: $color-white;
  padding: $global-spacing * 2 $global-spacing;

  @include screen-tablet-landscape-and-bigger {
    display: none;
  }

  .store__hours {
    display: none;

    &.is--expanded {
      display: flex;
    }
  }
}

.c-store-locator--page {
  .store__maps-link {
    transition: 0s;
    font-size: rem(14);
    font-weight: 700;
    text-decoration: none;

    svg {
      margin-right: 4px;
    }
  }

  .store-locator__item {
    width: 100%;
    text-align: left;
    border-bottom: $border-grey;

    &:first-child {
      padding-top: 0;

      @include screen-tablet-landscape-and-bigger {
        padding-top: rem(33);
        margin-bottom: 0;
      }
    }
  }

  .store-locator__item--active {
    border-color: transparent;

    svg {
      fill: transparent;

      path {
        stroke: $color-black;
      }
    }

    .store__maps-link {
      @include hover-focus {
        color: $color-black;
      }
    }

    * {
      color: $color-black;
      position: relative;
      z-index: 2;
    }

    .store__info-store-type {
      color: $color-white;
    }

    &:first-child {
      &::before {
        top: rem(-20);

        @include screen-tablet-landscape-and-bigger {
          top: rem(-1);
        }
      }
    }

    &::before {
      content: "";
      position: absolute;
      left: rem(-26);
      right: rem(-26);
      top: rem(-1);
      bottom: rem(-1);
      background-color: $color-blue-sky-light;
      z-index: 1;
    }
  }

  .accordion__item--is-active {
    border-top: 0;
  }

  .store-locator__map-selected-store {
    position: absolute;
  }

  .store-locator__form {
    margin-bottom: 0;
    width: 100%;
    display: flex;
  }

  .store-locator__form-row {
    display: flex;
    flex-wrap: wrap;
  }

  .store-locator__amount-found {
    margin-bottom: 0;
    display: flex;
    align-items: center;
  }

  .store-locator__stores-found-text {
    margin-left: $global-spacing / 2;
  }

  .store-locator__form-input--address {
    margin-left: 0;
  }

  .store-locator__results--map-is-active {
    display: none;

    @include screen-tablet-landscape-and-bigger {
      display: block;
    }
  }
}
