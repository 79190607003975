.back-to-top {
    position: fixed;
    bottom: 0;
    right: 0;
    margin: 0 rem(26) rem(30) 0;
    cursor: pointer;
    z-index: 5;
    -webkit-appearance: none;

    @include screen-laptop-and-bigger {
        margin: 0 rem(50) rem(40) 0;
    }
}