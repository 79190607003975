// -----------------------------------------------
// HEADER
// -----------------------------------------------

$header-height-top: 50px;
$header-height-bottom: 60px;
$header-height-bottom-desktop: 60px;

$header-transition-duration: 300ms;
$header-transition-ease: linear;

$header-logo-width-mobile: 64px;
$header-logo-width-desktop: 82px;
