.not-shoppable-login-actions {
  width: max-content;
  display: flex;

  .c-button {
    width: max-content;

    &:first-child {
      margin-right: 54px;
  
      @include screen-mobile-plus-and-smaller {
        margin-right: 48px;
      }
    }
  }
}
