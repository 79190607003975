// Custom scope for RTE styles.

@mixin rich-text-scope {
  color: currentColor;
  word-break: break-word;

  a[href]:not(.c-button) {
    @include hover-focus {
      color: var(--color-button-ghost-focus-text);
    }
  }

  ul:not(.list--basic) {
    @include list-reset;

    li {
      &::before {
        content: '';
        position: absolute;
        top: 0.5em;
        left: 0;
        display: inline-block;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: var(--color-text);
      }
    }
  }

  ol {
    @include list-reset;

    li {
      counter-increment: custom-counter;
      padding-left: $global-spacing-md;

      &::before {
        content: counter(custom-counter) '. ';
        position: absolute;
        top: 0;
        left: 0;
        display: inline-block;
      }
    }
  }

  ul:not(.list--basic),
  ol:not(.list--basic) {
    li {
      position: relative;
      padding-left: $global-spacing * 2;

      + li {
        margin-top: $global-spacing;
      }
    }
  }

  ol:not(.list--basic) {
    li {
      padding-left: $global-spacing-md;
    }
  }

  strong {
    font-weight: $font-weight-bold;
  }

  img {
    max-width: 100%;
    height: auto;
    display: block;

    + img {
      margin-top: $global-spacing-sm;
    }
  }

  > *:not(:first-child) + * {
    margin-top: 1em;
    margin-bottom: 0;
  }

  .c-definition-table {
    margin-top: $global-spacing-xxs;

    @include screen-tablet-portrait-and-bigger {
      margin-top: 0;
    }
  }

  .c-definition-table + .c-definition-table {
    margin-top: 1em;
  }

  p {
    margin-bottom: $global-spacing-md;



    + h1,
    + h2,
    + h3,
    + h4,
    + h5,
    + h6 {
      margin-top: 1.2em;
      margin-bottom: 0;
    }
  }

  ol,
  ul {
    + p,
    + ul,
    + ol {
      margin-top: 1em;
      margin-bottom: 0;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    + p {
      margin-top: 1em;
      margin-bottom: 0;
    }
  }

  .has--keyboard-focus & {
    a[href]:focus {
      outline: none;
      position: relative;
      @include focus-state-line-crawl;
    }
  }
}

.s-rich-text {
  @include rich-text-scope;

  &--secondary-title-style {
    h1 {
      @include h1-secondary;
      margin-bottom: $global-spacing-xlg;
    }

    h2 {
      @include h2-secondary;
      margin-bottom: $global-spacing-xlg;
    }

    h3 {
      @include h3-secondary;
      margin-bottom: $global-spacing-xlg;
    }
  }

  &--checklist {
    ul:not(.list--basic) li {
      padding-left: $global-spacing-md;

      &::before {
        color: var(--color-text);
        border-radius: 0;
        width: 10px;
        height: 7px;
        background-color: transparent;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' focusable='false' class='c-icon icon--check' viewBox='0 0 17 12' width='10' height='7'%3E%3Cpolygon fill='currentColor' points='16.3,0 5.9,10.6 0.7,5.3 0,6 5.9,12 17,0.7 '%3E%3C/polygon%3E%3C/svg%3E");
        background-repeat: no-repeat;
      }
    }
  }

  .experience-assets-richText & {
    @include default-pd-asset-space;

    h2 + h3 {
      margin-top: $global-spacing-md;
    }
  }

  &.accordion__item-content {
    p:last-child {
      margin-bottom: 0;
    }
  }

  blockquote {
    color: $color-dark-grey;
  }

  &.u-text--collapsable:not(.toggle--is-active) {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}
