.c-divider {
  padding-bottom: rem(15);

  @include screen-tablet-portrait-and-bigger {
    padding-bottom: rem(20);
  }

  &--padding-0 {
    padding: 0;

    @include screen-tablet-portrait-and-bigger {
      padding-bottom: 0;
    }
  }

  &--padding-xs {
    padding-top: rem(20);
  }

  &--padding-sm {
    padding-top: rem(30);
  }

  &--padding-md {
    padding-top: rem(60);

    @include screen-tablet-portrait-and-bigger {
      padding-top: rem(80);
    }
  }

  &--padding-lg {
    padding-top: rem(80);

    @include screen-tablet-portrait-and-bigger {
      padding-top: rem(120);
    }
  }
}

.divider__separator {
  @include divider-line(var(--color-text), bottom, after, false);
  display: block;
  height: rem(1);
  width: 100%;

  .background-animation--on & {
    @include divider-line(var(--background-update-color), bottom, after, false);
  }
}
