.story__col {
  display: flex;

  &--content {
    flex-direction: column;
    justify-content: space-between;
  }

  @include screen-tablet-portrait-and-smaller {
    &--image {
      margin-bottom: rem(40);
    }
  }
}

.story__text {
  @include width-override(18, 'max-width');

  > *:last-child {
    margin-bottom: 0;
  }

  @include screen-laptop-and-bigger {
    @include width-override(6, 'max-width');
  }
}

.story__title {
  margin-bottom: rem(40);
  text-transform: uppercase;

  @include screen-tablet-landscape-and-bigger {
    margin-bottom: rem(16);
  }
}

.story__label {
  margin-bottom: rem(16);
  color: $color-dark-grey;

  @include screen-tablet-landscape-and-bigger {
    @include width-override(-3, 'right');
    @include width-override(3, 'max-width');
    padding: 0 rem(8);
    position: absolute;
    top: 0;
    text-align: right;
  }
}