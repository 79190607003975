.c-store-locator-filters {
  &.c-paged-navigation-modal {
    left: 0;
  }

  @include screen-laptop-and-bigger {
    max-width: rem(1440);
    margin: 0 auto;
  }

  .store-locator__result .paged-navigation-modal__content {
    @include screen-laptop-and-bigger {
      margin: 0;
    }
  }

  .paged-navigation-modal__content {
    background-image: var(--color-background-image);

    @include screen-laptop-and-bigger {
      position: absolute;
    }
  }

  .modal__background {
    background-color: unset;
  }
}
