.c-navigation {
  width: 100%;
}

.navigation__item {
  display: block;
  padding: $global-spacing 0;
  text-decoration: none;
  background-image: none;
  border-bottom: 1px solid var(--color-divider-line);

  @include screen-tablet-landscape-and-bigger {
    border-bottom: 0;
  }

  @include hover-focus {
    .image__default {
      transform: scale(1.08);
    }
  }
}

.navigation__item-content {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include screen-tablet-landscape-and-bigger {
    flex-direction: row-reverse;
    justify-content: flex-end;
  }
}

.navigation__item-image-wrapper {
  width: 64px;
  height: 64px;
  overflow: hidden;
  background-color: var(--color-background-inverted-10);

  @include screen-tablet-landscape-and-bigger {
    margin-right: $global-spacing-lg;
  }
}

.navigation__item-image {
  width: inherit;

  img {
    width: 100%;
    height: 100%;
  }

  .image__default {
    transition: 400ms transform $ease-out-quad, opacity 200ms ease-out;
  }
}

.navigation__aside-image {
  width: 100%;

  img {
    width: 100%;
    height: auto;
  }
}
